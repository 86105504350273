import { useEffect, useState } from "react";
import { imgMarker } from "../../../utils/Images";
import AddressService from "../../../services/Address.service";

const AddressItem = (props) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const removeAddress = async (id) => {
    await AddressService.remove(id);
    props.refresh();
  };

  useEffect(() => {
    if (isDeleting) {
      removeAddress(props.item.id);
    }
  }, [isDeleting]);

  return (
    <div className="col-md-6">
      <div className="bg-white card addresses-item mb-4">
        <div className="p-4">
          <div className="d-flex">
            <div className="me-3">
              <img src={imgMarker} height={30} />
            </div>
            <div className="media-body">
              <h6 className="mb-1 text-secondary">{props.item.name}</h6>
              <p className="text-black">
                {props.item.post_code}, {props.item.address},{" "}
                {props.item.city_name}, {props.item.country_name}
              </p>
              <div className="mb-0 text-black font-weight-bold">
                <button
                  className="xbtn xsm me-2"
                  onClick={() => {
                    props.setEdtiAddressesItem(props.item);
                  }}
                >
                  Edit
                </button>
                <button
                  className={`xbtn xsm ${isDeleting ? "is-loading" : ""}`}
                  onClick={() => {
                    setIsDeleting(true);
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddressItem;
