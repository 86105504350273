function Breadcrumb() {
  return "";
  return (
    <>
      <h2 className="map mt-3">
        HOME / <span> INTERIOR</span>
      </h2>
    </>
  );
}

export default Breadcrumb;
