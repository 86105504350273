import config from "../config/config";
import apiWorker from "../utils/Api";

const root = `${config.root}/orders`;

const list = async () => {
  try {
    const res = await apiWorker().get(root);
    return res;
  } catch (error) {
    return false;
  }
};

const post = async (body) => {
  try {
    const res = await apiWorker().post(root, body);
    return res;
  } catch (error) {
    if (error && error.response) {
      return error.response;
    }
    return false;
  }
};

const pay = async (id, provider_id) => {
  try {
    const res = await apiWorker().post(root + "/pay", {
      order_id: id,
      payment_provider_id: provider_id,
    });
    return res;
  } catch (error) {
    if (error && error.response) {
      return error.response;
    }
    return false;
  }
};

const completePayment = async (payment_transaction_id) => {
  try {
    const res = await apiWorker().post(root + "/payments/complete", {
      payment_transaction_id: payment_transaction_id,
    });
    return res;
  } catch (error) {
    if (error && error.response) {
      return error.response;
    }
    return false;
  }
};

const one = async (id) => {
  try {
    const res = await apiWorker().get(`${root}/${id}`);
    return res;
  } catch (error) {
    if (error.response) {
      return error.response;
    }
    return false;
  }
};

export default { post, list, one, pay, completePayment };
