import { useEffect, useState } from "react";
import OrderService from "../../../services/Order.service";
import { imgLoading3 } from "../../../utils/Images";

const OrderItemExpanded = (props) => {
  const [data, setData] = useState(null);

  const load = async () => {
    const res = await OrderService.one(props.item.id);
    if (res && res.data && res.data.success) {
      setData(res.data.result);
    }
  };

  useEffect(() => {
    load();
  }, []);

  return !data ? (
    <img src={imgLoading3} className="d-block m-auto" height="80" />
  ) : (
    <div className="order-details d-block mt-3">
      <span className="line-between mb-3"></span>
      <div className="my-3">
        <div className="row product-row justify-content-between  align-items-center">
          <div className="col-7">
            <h2 className="col-text">Item Name</h2>
          </div>
          <div className="col text-center">
            <h2 className="col-text">Unit Price</h2>
          </div>
          <div className="col text-center">
            <h2 className="col-text">Quantity</h2>
          </div>
          <div className="col text-center">
            <h2 className="col-text">Total</h2>
          </div>
        </div>
        {data.items.map((o_item) => {
          return (
            <div
              key={o_item.id}
              className="row product-row justify-content-between mb-3 align-items-center"
            >
              <div className="col-7">
                <div className="d-flex align-items-center">
                  <div className="image-product me-2 flex">
                    <img
                      src={o_item.product.image?.path}
                      className="w-100 h-100"
                    />
                  </div>
                  <div>
                    <b className="mb-0 d-block">{o_item.product.name}</b>
                    {/* <span>{o_item.variation.sku}</span> */}
                  </div>
                </div>
              </div>
              <div className="col text-center">
                <p className="details-product ">
                  ${o_item.price}
                  {o_item.old_price != o_item.price && (
                    <span className="px-2 text-decoration-line-through d-block text-secondary">
                      ${o_item.old_price}
                    </span>
                  )}
                </p>
              </div>
              <div className="col text-center">
                <p className="details-product ">{o_item.quantity}</p>
              </div>
              <div className="col text-center">
                <p className="details-product ">
                  ${o_item.price * o_item.quantity}
                </p>
              </div>
            </div>
          );
        })}
        <span className="line-between my-3"></span>
      </div>

      <div className="summ">
        <div className="row justify-content-end">
          <div className="col-lg-3 col-sm-6">
            <p className="order-title">TAX</p>
          </div>
          <div className="col-lg-2 col-sm-6 text-center">
            <p className="total-num">%{data.tax}</p>
          </div>
        </div>
        {data.address && (
          <div className="row justify-content-end">
            <div className="col-lg-3 col-sm-6">
              <p className="order-title">Shipping Fees</p>
            </div>
            <div className="col-lg-2 col-sm-6 text-center">
              <p className="total-num">${data.delivery_fee}</p>
            </div>
          </div>
        )}
        <div className="row justify-content-end">
          <div className="col-lg-3 col-sm-6">
            <p className="order-title">Total</p>
          </div>
          <div className="col-lg-2 col-sm-6 text-center">
            <p className="total-num">${props.item.total}</p>
          </div>
        </div>
      </div>

      <div className="drop-style">
        <button
          className="form-control input-style d-flex justify-content-between border-0 "
          onClick={() => {
            props.setExpanded(false);
          }}
          type="button"
        >
          MINIMIZE
          <span>
            <svg
              width="16"
              height="10"
              viewBox="0 0 16 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8 0L15.7942 9.75H0.205771L8 0Z" fill="#676767" />
            </svg>
          </span>
        </button>
      </div>
    </div>
  );
};

export default OrderItemExpanded;
