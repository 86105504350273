import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { setFormLoading } from "../../utils/UiHelpers";
import { useState } from "react";

const PayWithStripe = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    setFormLoading(event.target);
    setError("");
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: window.location.origin + "/order/123/complete",
      },
    });

    if (result.error) {
      setError(result.error.message);
      setFormLoading(event.target, false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <span className="text-danger text-center d-block fw-bold mt-3">
        {error}
      </span>
      <div className="text-end">
        <button
          className="btn btn-submit mt-3"
          type="submit"
          disabled={!stripe}
        >
          Pay Now
        </button>
      </div>
    </form>
  );
};

export default PayWithStripe;
