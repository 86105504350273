import { imgCard } from "../../../utils/Images";

const PaymentMethodItem = (props) => {
  return (
    <div className="col-md-4">
      <div className="bg-white card addresses-item mb-4">
        <div className="p-4">
          <div className="d-flex">
            <div className="me-3">
              <img src={imgCard} height={30}/>
            </div>
            <div className="media-body">
              <h6 className="mb-1 text-secondary text-uppercase fw-bold">
                {props.item.credit_card.card_type}
              </h6>
              <p className="text-black mb-0 fs-5 fw-bold my-4">
                **** **** **** {props.item.credit_card.card_number}
              </p>
              <p className="text-black">
                <span className="text-secondary me-2">Card Holder:</span>
                <span className="fw-bold">
                  {props.item.name && props.item.name.length > 0
                    ? props.item.name
                    : "no name"}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentMethodItem;
