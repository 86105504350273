import PromotionBox from "./PromotionBox";

function Offers(props) {
  return (
    <>
      {/* <!--shope--> */}
      <div className="shope py-5">
        <div className="row">
          {props.promotions &&
            props.promotions.map((item) => {
              return (
                <div className="col-md-6">
                  <PromotionBox item={item} />
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
}

export default Offers;
