import { useEffect, useState } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import { useAuth } from "../../contexts/Auth.context";
import authService from "../../services/Auth.service";
import FormHandler from "../../utils/Handlers";
import { setFormLoading } from "../../utils/UiHelpers";
import { Link, useNavigate } from "react-router-dom";
import PageTitle from "../../components/PageTitle";

function LoginPage() {
  const navigate = useNavigate();
  const { setUser, setToken } = useAuth();
  const [form, setForm] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");
  const formHandler = new FormHandler(form, setForm);

  useEffect(() => {
    setError("");
  }, [form]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setFormLoading(e.target);
    let res = await authService.login(form);
    if (res) {
      if (res.data.success == true) {
        if (res.data.result.token) {
          setUser(res.data.result.user);
          setToken(res.data.result.token);
          navigate("/");
        } else {
          setError("Your account is under review and not approved yet.");
        }
      } else {
        setError(res.data.message);
      }
    } else {
      setError("error");
    }
    setFormLoading(e.target, false);
  };

  return (
    <>
      <div className="container">
        <br />
        <br />
        <PageTitle text="Welcome Back" />
        <div className="pb-5 pt-4 login-box m-auto">
          <form onSubmit={handleSubmit}>
            <div
              className="m-auto"
              style={{
                maxWidth: "400px",
              }}
            >
              <span className="text-danger text-start d-block fw-bold mb-3">
                {error}
              </span>
              <label className="xlabel">Email Address</label>
              <input
                autoFocus
                required
                name="email"
                type="email"
                placeholder="Email Address"
                className="xinput"
                value={form.email}
                onChange={formHandler.handleTextChange}
              />

              <label className="xlabel">Password</label>
              <input
                required
                name="password"
                type="password"
                placeholder="Password"
                className="xinput"
                value={form.password}
                onChange={formHandler.handleTextChange}
              />
              <div className="text-end mb-3">
                <Link
                  to="/forgot-password"
                  className="forgot-password text-decoration-none"
                >
                  Forgot Password?
                </Link>
              </div>
              <div className="text-center">
                <button type="submit" className="xbtn mb-4 my-2">
                  SIGN IN
                </button>
              </div>
              <hr className="mt-5" />
              <div className="new-account mt-4">
                Don’t have an account?
                <Link to="/register"> Create an account</Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default LoginPage;
