import { useEffect, useState } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import FindForm from "../../components/FindForm";
import Category from "../../components/Category";
import { useParams } from "react-router-dom";
import { useGlobal } from "../../contexts/Global.context";
import { deepSearchByKey, goTo } from "../../utils/GeneralHelpers";
import NoResults from "../../components/NoResults";

function CategoriesPage() {
  const [category, setCategory] = useState({});
  const { categories } = useGlobal();
  const { id } = useParams();

  useEffect(() => {
    if (!id) return;
    let category = deepSearchByKey(categories, "id", id);
    if (category.length > 0) {
      category = category[0];
      setCategory(category);
      goTo("#header");
    }
  }, [id, categories]);

  return (
    <>
      <div className="container">
        <Breadcrumb />
        <FindForm />

        <h2 id="header" className="title">
          {category.name}
        </h2>
        <div className="gray-box p-4 mb-5">
          <h2 className="order-num">Select Your Part Category</h2>
          <div className="categories-box mt-3">
            {!category.categories ? (
              <NoResults />
            ) : (
              <>
                <Category
                  item={category}
                  title={`All ${category.name}`}
                  goToSearch={1}
                />
                {category.categories.map((c, i) => {
                  return <Category item={c} key={i} />;
                })}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default CategoriesPage;
