import config from "../config/config";
import apiWorker from "../utils/Api";

const root = config.root;

const makes = async () => {
  try {
    const res = await apiWorker().get(`${root}/car-makes`);
    return res;
  } catch (error) {
    return false;
  }
};

const models = async (id) => {
  try {
    const res = await apiWorker().get(`${root}/car-models?car_make_id=${id}`);
    return res;
  } catch (error) {
    return false;
  }
};

const years = async (id) => {
  try {
    const res = await apiWorker().get(`${root}/car-years?car_model_id=${id}`);
    return res;
  } catch (error) {
    return false;
  }
};

const countries = async () => {
  try {
    const res = await apiWorker().get(`${root}/all-countries`);
    return res;
  } catch (error) {
    return false;
  }
};

const states = async (id) => {
  try {
    const res = await apiWorker().get(`${root}/all-states/${id}`);
    return res;
  } catch (error) {
    return false;
  }
};

const cities = async (id) => {
  try {
    const res = await apiWorker().get(`${root}/all-cities/${id}`);
    return res;
  } catch (error) {
    return false;
  }
};

export default { makes, models, years, countries, states, cities };
