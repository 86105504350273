import config from "../config/config";
import apiWorker from "../utils/Api";

const root = `${config.root}/contact-us`;

const post = async (body) => {
  try {
    const res = await apiWorker().post(root, body);
    return res;
  } catch (error) {
    return false;
  }
};

export default { post };
