import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import GlobalService from "../../services/Global.service";

const SideFindForm = () => {
  const navigate = useNavigate();
  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);
  const [years, setYears] = useState([]);
  const [isLoading, setIsLoading] = useState([-1, -1, -1]);
  const [form, setForm] = useState({
    car_make: null,
    car_model: null,
    car_year: null,
  });

  useEffect(() => {
    setIsLoading([1, -1, -1]);
    GlobalService.makes().then((res) => {
      setMakes(res.data.result.items);
      setIsLoading([0, 0, 0]);
    });
  }, []);

  const handleChange = async (e) => {
    let newForm = { ...form };
    switch (e.target.name) {
      case "car_make":
        newForm.car_make = e.target.value;
        newForm.car_model = null;
        newForm.car_year = null;
        setIsLoading([-1, 1, -1]);
        const mres = await GlobalService.models(e.target.value);
        setYears([]);
        setModels(mres.data.result.items);
        break;

      case "car_model":
        newForm.car_model = e.target.value;
        newForm.car_year = null;
        setIsLoading([-1, -1, 1]);
        const yres = await GlobalService.years(e.target.value);
        setYears(yres.data.result.items);
        break;

      case "car_year":
        newForm.car_year = e.target.value;
        break;
    }

    setIsLoading([0, 0, 0]);
    setForm({
      ...newForm,
    });
  };

  const handleFindBtn = (e) => {
    navigate(
      `/search?car_make=${form.car_make}&car_model=${form.car_model}&car_year=${form.car_year}`
    );
  };

  return (
    <div className="side-find-box">
      <div className="s-top">
        <h3>Search By Vehicle</h3>
        <p>
          Find the perfect fit for your vehicle by filtering your results with
          your vehicle information
        </p>
      </div>
      <div>
        <label className="xlabel">Car Make</label>
        <select
          defaultValue=""
          name="car_make"
          className={`xinput ${isLoading[0] == 1 ? "is-loading" : ""}`}
          disabled={isLoading[0] == -1}
          onChange={handleChange}
        >
          <option hidden value="">
            Choose Make
          </option>
          {makes
            ? makes.map((make) => {
                return (
                  <option key={make.id} value={make.id}>
                    {make.name}
                  </option>
                );
              })
            : ""}
        </select>
      </div>

      <div>
        <label className="xlabel">Car Model</label>
        <select
          name="car_model"
          className={`xinput ${isLoading[1] == 1 ? "is-loading" : ""}`}
          disabled={isLoading[1] == -1}
          onChange={handleChange}
        >
          <option hidden value="">
            Choose Model
          </option>
          {models
            ? models.map((model) => {
                return (
                  <option key={model.id} value={model.id}>
                    {model.name}
                  </option>
                );
              })
            : ""}
        </select>
      </div>

      <div>
        <label className="xlabel">Model Year</label>
        <select
          name="car_year"
          className={`xinput ${isLoading[2] == 1 ? "is-loading" : ""}`}
          disabled={isLoading[2] == -1}
          onChange={handleChange}
        >
          <option hidden value="">
            Choose Year
          </option>
          {years
            ? years.map((year) => {
                return (
                  <option key={year.id} value={year.id}>
                    {year.year}
                  </option>
                );
              })
            : ""}
        </select>
      </div>

      <div className="text-end">
        <button
          disabled={
            !form.car_make ||
            !form.car_model ||
            !form.car_year ||
            isLoading.includes(1) ||
            isLoading.includes(-1)
          }
          onClick={handleFindBtn}
          className="xbtn"
        >
          Find Parts
        </button>
      </div>
    </div>
  );
};

export default SideFindForm;
