import { Link } from "react-router-dom";

function PromotionBox(props) {
    
  return !props.item ? (
    ""
  ) : (
    <>
      <div className="newToShope position-relative">
        <img className="w-100 h-100" src={props.item.image} />
        <div className="position-absolute top-0 start-0 p-4 desshope">
          <h3 className="titleShop">{props.item.name}</h3>
          <h2 className="nameproduct">{props.item.short_description}</h2>
          <Link to="/search" className="red-btn small">
            SHOP NOW
          </Link>
        </div>
      </div>
    </>
  );
}

export default PromotionBox;
