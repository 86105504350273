import { useEffect, useState } from "react";
import OrderSummary from "./OrderSummary";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PayWithStripe from "./PayWithStripe";
import OrderService from "../../services/Order.service";
import { useParams } from "react-router";
import PaymentService from "../../services/Payment.service";
import { useNavigate } from "react-router-dom";
import { imgLoading3 } from "../../utils/Images";

const OrderPayPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();

  const [order, setOrder] = useState(null);
  const [stripePromise, setStripePromise] = useState(null);
  const [paymentProvider, setPaymentProvider] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const [paymentType, setPaymentType] = useState(null);

  const options = {
    clientSecret,
  };

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    setIsLoading(true);
    const res = await OrderService.one(id);
    if (res && res.data && res.data.success == true) {
      if (res.data.result.payment_transaction) {
        setPaymentType("complete");
        if (!res.data.result.payment_transaction.can_completed) {
          completePayment(res.data.result.payment_transaction.id);
          navigate("/profile/orders");
          return;
        }
      } else {
        setPaymentType("new");
      }
      setOrder(res.data.result);
    }
    loadStripeProvider();
    setIsLoading(false);
  };

  const completePayment = async (payment_transaction_id) => {
    const res = await OrderService.completePayment(payment_transaction_id);
    if (res && res.data && res.data.success) {
      setClientSecret(res.data.result.client_result.client_secret);
    }
  };

  const newPayWithProvider = async () => {
    const res = await OrderService.pay(order.id, paymentProvider.id);
    if (res && res.data && res.data.success) {
      setClientSecret(res.data.result.client_result.client_secret);
    }
  };

  const loadStripeProvider = async () => {
    const p = await PaymentService.stripe();
    if (p) {
      setPaymentProvider(p);
    }
  };

  useEffect(() => {
    if (paymentProvider && order && paymentType) {
      if (!stripePromise)
        setStripePromise(loadStripe(paymentProvider.public_key));

      if (paymentType == "new") {
        newPayWithProvider();
      }

      if (paymentType == "complete") {
        completePayment(order.payment_transaction.id);
      }
    }
  }, [paymentProvider, order]);

  return (
    <>
      <div className={`order py-5 ${isLoading ? "is-loading" : ""}`}>
        <div className="container">
          <h2 className="title">Order Payment</h2>

          <div className="row">
            <div className="col-md-8">
              <div
                style={{
                  maxWidth: "600px",
                  margin: "auto",
                }}
              >
                <h2 className="card-summary my-3 mt-5">Card Details</h2>
                {stripePromise && options.clientSecret ? (
                  <Elements options={options} stripe={stripePromise}>
                    <PayWithStripe />
                  </Elements>
                ) : (
                  <img
                    src={imgLoading3}
                    className="d-block m-auto"
                    height="80"
                  />
                )}
              </div>
            </div>

            <div className="col-md-4">
              <OrderSummary item={order} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderPayPage;
