import { useEffect, useState } from "react";
import AddressService from "../../../services/Address.service";
import { imgMarker } from "../../../utils/Images";
import { useGlobal } from "../../../contexts/Global.context";
import AddEditAddressModal from "../../../components/modals/AddEditAddress.modal";
import AddressItem from "./AddressItem";
import PageTitle from "../../../components/PageTitle";

const AddressesPage = () => {
  const [addressesRes, setAddressesRes] = useState(null);
  const [edtiAddressesItem, setEdtiAddressesItem] = useState(null);
  const { showModal } = useGlobal();

  const load = async () => {
    setAddressesRes(null);
    const res = await AddressService.list();
    setAddressesRes(res.data.result);
  };

  useEffect(() => {
    load();
  }, []);

  const onAddressAddEdit = () => {
    load();
  };

  return (
    <>
      <div className="row">
        <div className="col-md-4"></div>
        <div className="col-md-4">
          <PageTitle text="My Addresses" />
        </div>
        <div className="col-md-4 text-end mb-3">
          <button
            onClick={() => {
              showModal("address");
            }}
            className="xbtn sm me-2"
          >
            Add Address
          </button>
        </div>
      </div>

      {addressesRes &&
        addressesRes.addresses &&
        addressesRes.addresses.length == 0 && (
          <div className="text-center my-5 fw-bold">No Addresses</div>
        )}

      <div className="row">
        {addressesRes &&
          addressesRes.addresses &&
          addressesRes.addresses.map((item) => {
            return (
              <AddressItem
                key={item.id}
                refresh={load}
                item={item}
                setEdtiAddressesItem={setEdtiAddressesItem}
              />
            );
          })}
      </div>
      <AddEditAddressModal
        onSuccess={onAddressAddEdit}
        edtiAddressesItem={edtiAddressesItem}
        setEdtiAddressesItem={setEdtiAddressesItem}
      />
    </>
  );
};

export default AddressesPage;
