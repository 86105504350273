import { imgPart1 } from "../../utils/Images";
import Slider from "react-slick";
import { useState } from "react";

function ImagesSlider(props) {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();

  const nav2Settings = {
    infinite: true,
    slidesToShow: 3,
    arrows: false,
    asNavFor: nav1,
    centerMode: true,
    focusOnSelect: true,
  };

  const nav1Settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: nav2,
  };

  const getSlides = props.items
    ? props.items.map((item, i) => {
        return (
          <div key={i}>
            <div className="img">
              <img src={item.path} />
            </div>
          </div>
        );
      })
    : [];

  return (
    <>
      <div className="imgs-slider">
        {props.items && props.items.length > 0 ? (
          <>
            <Slider
              className="slider slider-single"
              {...nav1Settings}
              ref={(slider1) => setNav1(slider1)}
            >
              {getSlides}
            </Slider>
            <div className="slider slider-nav">
              <Slider {...nav2Settings} ref={(slider2) => setNav2(slider2)}>
                {getSlides}
              </Slider>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default ImagesSlider;
