import { useEffect, useState } from "react";
import authService from "../../services/Auth.service";
import FormHandler from "../../utils/Handlers";
import { setFormLoading } from "../../utils/UiHelpers";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import PageTitle from "../../components/PageTitle";
import { toast_success } from "../../utils/messages";

function ResetPasswordPage() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [form, setForm] = useState({
    email: searchParams.get("email"),
    reset_code: "",
    password: "",
    password_confirm: "",
  });
  const [error, setError] = useState("");
  const formHandler = new FormHandler(form, setForm);

  useEffect(() => {
    setError("");
  }, [form]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (form.password != form.password_confirm) {
      setError("Password doesn't match");
      return;
    }

    setError("");
    setFormLoading(e.target);
    let res = await authService.resetPassword(form);
    if (res) {
      if (res.data.success == true) {
        toast_success("Password reset successfully. Please login");
        navigate("/login");
      } else {
        setError(res.data.message);
      }
    } else {
      setError("error");
    }
    setFormLoading(e.target, false);
  };

  return (
    <>
      <div className="container">
        <br />
        <br />
        <PageTitle text="Set a New Password" />
        <div className="pb-5 pt-4 login-box m-auto">
          <form onSubmit={handleSubmit}>
            <div
              className="m-auto"
              style={{
                maxWidth: "400px",
              }}
            >
              <span className="mb-3 d-block text-center">
                A verification code has been sent to your email. Please check
                your inbox and enter the code below to proceed with resetting
                your password
              </span>
              <span className="text-danger text-start d-block fw-bold mb-3">
                {error}
              </span>
              <label className="xlabel">Verification Code</label>
              <input
                autoFocus
                required
                name="reset_code"
                type="text"
                placeholder="Verification Code"
                className="xinput"
                value={form.reset_code}
                onChange={formHandler.handleTextChange}
              />

              <label className="xlabel">New Password</label>
              <input
                autoFocus
                required
                name="password"
                type="password"
                placeholder="New Password"
                className="xinput"
                value={form.password}
                onChange={formHandler.handleTextChange}
              />

              <label className="xlabel">Confirm New Password</label>
              <input
                autoFocus
                required
                name="password_confirm"
                type="password"
                placeholder="Confirm New Password"
                className="xinput"
                value={form.password_confirm}
                onChange={formHandler.handleTextChange}
              />

              <div className="text-center">
                <button type="submit" className="xbtn mb-4 my-2">
                  SUBMIT
                </button>
              </div>
              <hr className="mt-5" />
              <div className="new-account mt-4">
                Back to
                <Link to="/login"> Login</Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default ResetPasswordPage;
