import { useContext, useEffect, useState, createContext } from "react";
import CartService from "../services/Cart.service";
import { useAuth } from "./Auth.context";

const ThisContext = createContext();

export function useCart() {
  return useContext(ThisContext);
}

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState(null);
  const { isAuth } = useAuth();

  useEffect(() => {
    if (isAuth()) refreshCart();
  }, [isAuth]);

  const refreshCart = async () => {
    if (!isAuth()) return;
    const res = await CartService.list();
    setCart(res.data.result);
  };

  const addToCart = async (item) => {
    const res = await CartService.post(item);
    if (res) {
      setCart(res.data.result);
    }
    return res;
  };

  const updateInCart = async (id, body) => {
    const res = await CartService.put(id, body);
    if (res && res.data && res.data.success) {
      setCart(res.data.result);
    }
    return res;
  };

  const removeFromCart = async (id) => {
    const res = await CartService.remove(id);
    if (res) {
      setCart(res.data.result);
    }
    return res;
  };

  return (
    <ThisContext.Provider
      value={{ cart, addToCart, updateInCart, refreshCart, removeFromCart }}
    >
      {children}
    </ThisContext.Provider>
  );
};
