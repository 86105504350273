import { NavLink } from "react-router-dom";
import { useAuth } from "../../contexts/Auth.context";
import {
  imgAvatar,
  imgCard,
  imgCart,
  imgLogout,
  imgMarker,
  imgUser,
} from "../../utils/Images";

const Sidebar = () => {
  const { getUser, logout } = useAuth();

  return (
    getUser() && (
      <div className="side">
        <div className="position-sticky top-0">
          <div className="profile-banner">
            <img src={imgAvatar} className="profile-img" />
            <h1>
              {getUser().first_name} {getUser().last_name}
            </h1>
          </div>

          <div className="steps-pr">
            <NavLink to="/profile/info">
              <div className="step-item">
                <img src={imgUser} />
                <span>Personal Information</span>
              </div>
            </NavLink>
            <NavLink to="/profile/orders">
              <div className="step-item">
                <img src={imgCart} />
                <span>My Orders</span>
              </div>
            </NavLink>
            <NavLink to="/profile/payment-methods">
              <div className="step-item">
                <img src={imgCard} />
                <span>Payment Methods</span>
              </div>
            </NavLink>
            <NavLink to="/profile/addresses">
              <div className="step-item">
                <img src={imgMarker} />
                <span>My Addresses</span>
              </div>
            </NavLink>
            <NavLink
              to="/"
              onClick={() => {
                logout();
              }}
              className="logout-a"
            >
              <div className="step-item">
                <img src={imgLogout} />
                <span>Logout</span>
              </div>
            </NavLink>
          </div>
        </div>
        <span
          style={{
            position: "absolute",
            bottom: 3,
            left: 15,
            opacity: 0.3,
          }}
        >
          v1.7
        </span>
      </div>
    )
  );
};

export default Sidebar;
