import { Link } from "react-router-dom";
import { useGlobal } from "../../contexts/Global.context";

function GoLogin(props) {
  const { modals, hideModal } = useGlobal();
  return (
    <>
      <div
        className={`modal fade ${modals.goLogin ? "show" : ""}`}
        tabIndex="-1"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title fw-bold">Login Required</h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => {
                  hideModal("goLogin");
                }}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p className="text-center m-0">
                Please login or create account to continue
              </p>
              <br />
              <div>
                <Link
                  onClick={() => {
                    hideModal("goLogin");
                  }}
                  to="/login"
                  className="btn my-0 btn-submit w-100"
                >
                  LOGIN TO YOUR ACCOUNT
                </Link>
              </div>
              <p className="text-center my-3">OR</p>
              <div>
                <Link
                  onClick={() => {
                    hideModal("goLogin");
                  }}
                  to="/register"
                  className="btn btn2 my-0 btn-submit-nobg big-btn w-100"
                >
                  CREATE NEW ACCOUNT
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modals.goLogin ? (
        <div
          onClick={() => {
            hideModal("goLogin");
          }}
          className={`modal-backdrop fade show`}
        ></div>
      ) : (
        ""
      )}
    </>
  );
}

export default GoLogin;
