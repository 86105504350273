import axios from "axios";
import { default as globalConfig } from "../config/config";

const apiWorker = (headers = {}) => {
  const worker = axios.create();
  worker.interceptors.request.use(function (config) {
    let token = localStorage.getItem("token") ?? null;
    config.headers["branch-id"] = globalConfig.defaultBranchId;
    config.headers["company-id"] = globalConfig.defaultCompanyId;
    config.headers = {
      ...config.headers,
      ...headers,
    };

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    
    return config;
  });

  worker.interceptors.response.use(
    function (response) {
      if (response.data && response.data.success == false) {
        //error
      }
      return response;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  return worker;
};

export default apiWorker;
