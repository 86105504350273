import { imgNoResult } from "../utils/Images";

const NoResults = () => {
  return (
    <div className="text-center w-100">
      <img style={{ opacity: 0.3 }} height={80} src={imgNoResult} />
      <div>No Results</div>
    </div>
  );
};

export default NoResults;
