import { useEffect, useState } from "react";
import OrderItem from "./OrderItem";
import OrderService from "../../../services/Order.service";
import PageTitle from "../../../components/PageTitle";

const OrdersPage = () => {
  const [ordersRes, setOrdersRes] = useState(null);

  const load = async () => {
    setOrdersRes(null);
    const res = await OrderService.list();
    setOrdersRes(res.data.result);
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <>
      <PageTitle text="My Orders" />
      {ordersRes && ordersRes.length == 0 && (
        <div className="text-center my-5 fw-bold">No Orders</div>
      )}
      {ordersRes &&
        ordersRes.map((item) => {
          return <OrderItem key={item.id} item={item} />;
        })}
    </>
  );
};

export default OrdersPage;
