import Pagination from "react-js-pagination";

const ResultPagination = (props) => {
  return (
    <div className="d-flex justify-content-center mt-5">
      <Pagination
        activePage={props.current_page}
        itemsCountPerPage={props.page_size ?? 0}
        totalItemsCount={props.total_items}
        pageRangeDisplayed={7}
        itemClass="page-item"
        linkClass="page-link"
        onChange={(page) => {
          let params = Object.fromEntries([...props.searchParams]);
          let newParams = { ...params };
          newParams.page = page;
          props.setSearchParams({
            ...newParams,
          });
        }}
      />
    </div>
  );
};

export default ResultPagination;
