import { useEffect, useState } from "react";
import PageTitle from "../../../components/PageTitle";
import PaymentMethodService from "../../../services/PaymentMethod.service";
import PaymentMethodItem from "./PaymentMethodItem";

const PaymentMethodsPage = () => {
  const [response, setResponse] = useState(null);

  const load = async () => {
    setResponse(null);
    const res = await PaymentMethodService.list();
    setResponse(res.data.result);
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-md-4"></div>
        <div className="col-md-4">
          <PageTitle text="My Payment Methods" />
        </div>
      </div>

      {response &&
        response.payment_methods &&
        response.payment_methods.length == 0 && (
          <div className="text-center my-5 fw-bold">No Payment Methods</div>
        )}

      <div className="row">
        {response &&
          response.payment_methods &&
          response.payment_methods.map((item) => {
            return (
              <PaymentMethodItem
                key={item.id}
                refresh={load}
                item={item}
              />
            );
          })}
      </div>
    </>
  );
};

export default PaymentMethodsPage;
