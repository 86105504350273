import { useEffect, useState } from "react";
import { useAuth } from "../../../contexts/Auth.context";
import FormHandler from "../../../utils/Handlers";
import { setFormLoading } from "../../../utils/UiHelpers";
import AuthService from "../../../services/Auth.service";
import PageTitle from "../../../components/PageTitle";

const PersonalInfoPage = () => {
  const { user, refreshUser, isAuth } = useAuth();
  const [error, setError] = useState("");

  const [form, setForm] = useState(user);
  const formHandler = new FormHandler(form, setForm);

  useEffect(() => {
    if (isAuth()) {
      setForm(user);
    }
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setFormLoading(e.target);
    let res = await AuthService.update(form);
    if (res) {
      if (res.data && res.data.success == true) {
        refreshUser();
      } else {
        setError(res.data.message);
      }
    } else {
      setError("error");
    }
    setFormLoading(e.target, false);
  };
  return (
    <form onSubmit={handleSubmit}>
      <PageTitle text="Personal Information" />

      {form && (
        <>
          <div
            style={{
              width: "100%",
              maxWidth: "500px",
              margin: "auto",
            }}
          >
            <div className="row">
              <div className="col-md-12">
                <label className="xlabel">First Name</label>
                <input
                  autoFocus
                  required
                  name="first_name"
                  type="text"
                  placeholder="First Name"
                  className="xinput"
                  value={form.first_name}
                  onChange={formHandler.handleTextChange}
                />
              </div>

              <div className="col-md-12">
                <label className="xlabel">Last Name</label>
                <input
                  required
                  name="last_name"
                  type="text"
                  placeholder="Last Name"
                  className="xinput"
                  value={form.last_name}
                  onChange={formHandler.handleTextChange}
                />
              </div>

              <div className="col-md-12">
                <label className="xlabel">Email Address</label>
                <input
                  readOnly
                  type="text"
                  className="xinput"
                  value={form.email}
                  onChange={formHandler.handleTextChange}
                />
              </div>

              <div className="col-md-12">
                <label className="xlabel">Phone Number</label>
                <div className="d-flex">
                  <input
                    required
                    type="number"
                    name="phone_code"
                    className="xinput me-3"
                    placeholder=""
                    value={form.phone_code}
                    style={{
                      width: "100px",
                    }}
                    onChange={formHandler.handleTextChange}
                  />
                  <input
                    required
                    type="number"
                    name="phone_number"
                    className="xinput"
                    placeholder="Phone Number"
                    value={form.phone_number}
                    onChange={formHandler.handleTextChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="text-center">
            <span className="text-danger text-center d-block fw-bold mb-3">
              {error}
            </span>
            <button className="xbtn">Save Changes</button>
          </div>
        </>
      )}
    </form>
  );
};

export default PersonalInfoPage;
