import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";

const ProfilePage = () => {
  return (
    <div className="company-register-page profile-page">
      <Sidebar />

      <div className="right-side">
        <Outlet />
      </div>
    </div>
  );
};
export default ProfilePage;
