import { Link } from "react-router-dom";
import { useGlobal } from "../../contexts/Global.context";
import { imgAngleDown } from "../../utils/Images";
import { useEffect, useState } from "react";
import { deepSearchByKey } from "../../utils/GeneralHelpers";

const CategoriesDrop = (props) => {
  const { categories } = useGlobal();
  const [currentCategories, setCurrentCategories] = useState([]);
  const [parent, setParent] = useState(null);

  const handleClick = (e, category, isBack = false) => {
    if (category.categories && category.categories.length > 0) {
      e.preventDefault();
      if (isBack) {
        const parent = getParent(category.parent_id);
        if (parent) {
          setCurrentCategories(parent.categories);
          setParent(parent);
        } else {
          setCurrentCategories(categories);
          setParent(null);
        }
      } else {
        setCurrentCategories(category.categories);
        setParent(category);
      }

      return;
    }
    props.setIsCategoryDrop && props.setIsCategoryDrop(false);
    setCurrentCategories(categories);
    setParent(false);
  };

  const getParent = (id) => {
    if (id) {
      const parent = deepSearchByKey(categories, "id", id);
      if (parent && parent.length > 0) {
        return parent[0];
      }
    }
    return null;
  };

  useEffect(() => {
    setCurrentCategories(categories);
  }, [categories]);

  return (
    <>
      <div className="cats-drop-box">
        <h3>Select Category</h3>
        <div className="content">
          <ul>
            {parent && (
              <li key={parent.id}>
                <Link
                  to={`/search?category=${parent.id}`}
                  onClick={(e) => {
                    handleClick(e, parent, true);
                  }}
                  className="back-link"
                >
                  Back, {parent.name}
                  {parent.categories && parent.categories.length > 0 && (
                    <img src={imgAngleDown} className="arr" />
                  )}
                </Link>
              </li>
            )}
            {currentCategories.map((cat) => {
              return (
                <li key={cat.id}>
                  <Link
                    to={`/search?category=${cat.id}`}
                    onClick={(e) => {
                      handleClick(e, cat);
                    }}
                  >
                    {cat.name}
                    {cat.categories && cat.categories.length > 0 && (
                      <img src={imgAngleDown} className="arr" />
                    )}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default CategoriesDrop;
