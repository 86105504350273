import { useGlobal } from "../../contexts/Global.context";
import { useEffect, useRef, useState } from "react";
import FormHandler from "../../utils/Handlers";
import { setFormLoading } from "../../utils/UiHelpers";
import GlobalService from "../../services/Global.service";
import AddressService from "../../services/Address.service";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import { extractPlaceData } from "../../utils/GeneralHelpers";

function AddEditAddressModal(props) {
  const country_id = 233;
  const { modals, hideModal } = useGlobal();
  const [error, setError] = useState("");
  const [states, setStates] = useState([{ ss: "s" }]);
  const formRef = useRef();
  const [newPlace, setNewPlace] = useState();

  const [form, setForm] = useState({
    country_id,
    state_id: "",
    city_name: "",
    address: "",
  });
  const formHandler = new FormHandler(form, setForm);

  const loadStates = async () => {
    const mres = await GlobalService.states(country_id);
    setStates(mres.data.result);
  };

  useEffect(() => {
    loadStates();
  }, []);

  useEffect(() => {
    if (newPlace) {
      const placeInfo = extractPlaceData(newPlace);
      const foundedState = states?.find(
        (st) => st.code == placeInfo.state_code
      );
      let state_id = foundedState ? foundedState.id : undefined;

      setForm({
        ...form,
        state_id,
        address: newPlace.formatted_address,
      });

      setNewPlace(null);
    }
  }, [newPlace]);

  const handleNewPlace = (place) => {
    setNewPlace(place);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setFormLoading(e.target);
    let res = null;
    if (form.id) {
      res = await AddressService.put(form.id, form);
    } else {
      res = await AddressService.post(form);
    }

    if (res) {
      if (res.data && res.data.success == true) {
        if (props.onSuccess) {
          props.onSuccess();
        }
        hideModal("address");
        props.setEdtiAddressesItem(null);
        setForm({});
        e.target.reset();
      } else {
        setError(res.data.message);
      }
    } else {
      setError("error");
    }
    setFormLoading(e.target, false);
  };

  const closeModal = () => {
    hideModal("address");
    props.setEdtiAddressesItem(null);
  };

  useEffect(() => {
    if (props.edtiAddressesItem) {
      setForm({
        id: props.edtiAddressesItem.id,
        name: props.edtiAddressesItem.name,
        country_id: props.edtiAddressesItem.country_id,
        city_name: props.edtiAddressesItem.city_name,
        state_id: props.edtiAddressesItem.state_id,
        address: props.edtiAddressesItem.address,
        post_code: props.edtiAddressesItem.post_code,
      });
    }
  }, [props.edtiAddressesItem]);

  return (
    <>
      <div
        className={`modal fade ${
          modals.address || props.edtiAddressesItem ? "show" : ""
        }`}
        tabIndex="-1"
      >
        <div className="modal-dialog  modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title fw-bold">
                {form?.id ? "Edit Address" : "Add New Address"}
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={closeModal}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit} ref={formRef}>
                {form && (
                  <>
                    <div>
                      <div className="row">
                        <div className="col-md-12">
                          <label className="xlabel">
                            Address Name
                          </label>
                          <input
                            autoFocus
                            name="name"
                            type="text"
                            placeholder="Address Name"
                            className="xinput"
                            value={form.name}
                            onChange={formHandler.handleTextChange}
                          />
                        </div>
                        <div className="col-md-12">
                          <label className="xlabel">
                            Address
                            <span className="position-absolute translate-middle p-2 ms-2 icon">
                              *
                            </span>
                          </label>
                          <div className="position-relative z-30">
                            <ReactGoogleAutocomplete
                              className="xinput"
                              apiKey={"AIzaSyAQAguywqi0xr2wr42MB87vXgWdrs20F2o"}
                              onPlaceSelected={handleNewPlace}
                              defaultValue={form?.address}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  address: e.target.value,
                                });
                              }}
                              onKeyDown={(e) => {
                                if (e.key == "Enter") {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-md-12">
                          <label className="xlabel">
                            State
                            <span className="position-absolute translate-middle p-2 ms-2 icon">
                              *
                            </span>
                          </label>
                          <select
                            required
                            name="state_id"
                            value={form.state_id}
                            onChange={(e) =>
                              setForm({ ...form, state_id: e.target.value })
                            }
                            className="xinput"
                          >
                            <option value="" hidden>
                              Select State
                            </option>
                            {states
                              ? states.map((c) => {
                                  return (
                                    <option key={c.id} value={c.id}>
                                      {c.name}
                                    </option>
                                  );
                                })
                              : ""}
                          </select>
                        </div>

                        <div className="col-md-12">
                          <label className="xlabel">
                            City
                            <span className="position-absolute translate-middle p-2 ms-2 icon">
                              *
                            </span>
                          </label>
                          <input
                            autoFocus
                            required
                            name="city_name"
                            type="text"
                            placeholder="City"
                            className="xinput"
                            value={form.city_name}
                            onChange={formHandler.handleTextChange}
                          />
                        </div>

                        <div className="col-md-12">
                          <label className="xlabel">
                            Post Code
                            <span className="position-absolute translate-middle p-2 ms-2 icon">
                              *
                            </span>
                          </label>
                          <input
                            required
                            name="post_code"
                            type="string"
                            className="xinput"
                            value={form.post_code}
                            onChange={formHandler.handleTextChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="text-center">
                      <span className="text-danger text-center d-block fw-bold mb-3">
                        {error}
                      </span>
                      <button className="xbtn">Save</button>
                    </div>
                  </>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
      {modals.address || props.edtiAddressesItem ? (
        <div onClick={closeModal} className={`modal-backdrop fade show`}></div>
      ) : (
        ""
      )}
    </>
  );
}

export default AddEditAddressModal;
