import { useEffect, useState } from "react";
import authService from "../../services/Auth.service";
import FormHandler from "../../utils/Handlers";
import { setFormLoading } from "../../utils/UiHelpers";
import { Link, useNavigate } from "react-router-dom";
import PageTitle from "../../components/PageTitle";

function ForgotPasswordPage() {
  const navigate = useNavigate();
  const [form, setForm] = useState({
    email: "",
  });
  const [error, setError] = useState("");
  const formHandler = new FormHandler(form, setForm);

  useEffect(() => {
    setError("");
  }, [form]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setFormLoading(e.target);
    let res = await authService.forgotPassword(form);
    if (res) {
      if (res?.data?.success == true) {
        navigate("/reset-password?email=" + form.email);
      } else {
        setError(res.data.message);
      }
    } else {
      setError("error");
    }
    setFormLoading(e.target, false);
  };

  return (
    <>
      <div className="container">
        <br />
        <br />
        <PageTitle text="Forgot Password" />
        <div className="pb-5 pt-4 login-box m-auto">
          <form onSubmit={handleSubmit}>
            <div
              className="m-auto"
              style={{
                maxWidth: "400px",
              }}
            >
              <span className="text-danger text-start d-block fw-bold mb-3">
                {error}
              </span>
              <label className="xlabel">Email Address</label>
              <input
                autoFocus
                required
                name="email"
                type="email"
                placeholder="Email Address"
                className="xinput"
                value={form.email}
                onChange={formHandler.handleTextChange}
              />

              <div className="text-center">
                <button type="submit" className="xbtn mb-4 my-2">
                  SUBMIT
                </button>
              </div>
              <hr className="mt-5" />
              <div className="new-account mt-4">
                Back to
                <Link to="/login"> Login</Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default ForgotPasswordPage;
