import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Product from "../../components/Product";
import { useGlobal } from "../../contexts/Global.context";
import ProductService from "../../services/Product.service";
import { deepSearchByKey, goTo } from "../../utils/GeneralHelpers";
import { imgLoading3, imgNoResult } from "../../utils/Images";
import FilterSidebar from "./FilterSidebar";
import ResultPagination from "./Pagination";
import NoResults from "../../components/NoResults";
import PageTitle from "../../components/PageTitle";

function SearchPage() {
  const [category, setCategory] = useState({});
  const { categories } = useGlobal();
  const [filters, setFilters] = useState(null);
  const [query, setQuery] = useState({});
  const [productsRes, setProductsRes] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [timer, setTimer] = useState(null);
  const [page, setPage] = useState(1);
  const [isFiltersSide, setIsFiltersSide] = useState(false);
  //   const [firstTime, setFirstTime] = useState(true);

  useEffect(() => {
    let params = Object.fromEntries([...searchParams]);

    // if (firstTime) {
    //   params.page = params.page ? params.page : 1;
    //   params.order_by_clauses = params.order_by_clauses
    //     ? params.order_by_clauses
    //     : "OrderByNewest";
    //   params.page_size = params.page_size ? params.page_size : 15;
    //   params.brand = params.brand ? params.brand : "";
    //   params.text = params.text ? params.text : "";
    //   params.car_make = params.car_make ? params.car_make : "";
    //   params.car_model = params.car_model ? params.car_model : "";
    //   params.car_year = params.car_year ? params.car_year : "";
    //   setFirstTime(false);
    //   setTimeout(() => {
    //     setSearchParams({
    //       ...params,
    //     });
    //   }, 150);
    //   return;
    // }

    if (categories.length == 0 || Object.keys(params).length == 0) return;
    if (params.category) {
      let category = deepSearchByKey(categories, "id", params.category);
      if (category.length > 0) {
        category = category[0];
        setCategory(category);
      }
    }

    goTo("#header");

    if (params.brand) {
      params.brand = params.brand.split("-");
    } else {
      params.brand = [];
    }

    setQuery({
      ...params,
    });
  }, [searchParams, categories]);

  useEffect(() => {
    ProductService.filters().then((res) => {
      if (res) {
        setFilters(res.data.result);
      }
    });
  }, []);

  useEffect(() => {
    if (Object.keys(query).length == 0) return;
    const load = async (search) => {
      setProductsRes(null);
      const res = await ProductService.list(search);
      setProductsRes(res.data.result);
    };

    clearTimeout(timer);
    setTimer(
      setTimeout(() => {
        load(query);
      }, 1000)
    );
  }, [query]);

  const handleChange = (e) => {
    let params = Object.fromEntries([...searchParams]);
    let newParams = { ...params };

    newParams.page = 1;

    if (e.target.name == "brand") {
      if (!newParams.brand) newParams.brand = "";
      let brand = newParams.brand.split("-");
      brand = brand.filter((b) => b != "");
      if (e.target.checked) {
        brand.push(e.target.value);
      } else {
        brand = brand.filter((b) => b != e.target.value);
      }

      brand = brand.join("-");
      newParams.brand = brand;
    } else {
      newParams[e.target.name] = e.target.value;
    }

    setSearchParams({
      ...newParams,
    });
  };

  return (
    <>
      <div className="container pt-3">
        <div className="row search-row mt-3">
          {/* <!--sidebar--> */}
          <div className="col-lg-3 text-start sidebar">
            <FilterSidebar
              filters={filters}
              query={query}
              setQuery={setQuery}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              handleChange={handleChange}
              isFiltersSide={isFiltersSide}
              setIsFiltersSide={setIsFiltersSide}
            />
          </div>
          <div className="col-lg-9">
            <div className="row">
              <div className="col-12">
                <PageTitle
                  text={
                    category && category.name ? category.name : "Search Result"
                  }
                  className="mb-5"
                />
              </div>
            </div>
            {productsRes != null ? (
              <>
                <div>
                  <div className="in-search-top-bar">
                    <div className="left-box">
                      <div className="d-flex align-items-center">
                        <span className="me-2 order-font fw-bold">Show:</span>
                        <select
                          className="xinput mb-0 w-auto"
                          value={query.page_size}
                          name="page_size"
                          onChange={handleChange}
                        >
                          <option value={15}>15 items</option>
                          <option value={30}>30 items</option>
                          <option value={45}>45 items</option>
                        </select>
                      </div>

                      <div className="d-flex align-items-center">
                        <span className="ms-4 me-2 order-font fw-bold">
                          Sort by:
                        </span>
                        <select
                          className="xinput mb-0 w-auto"
                          value={query.order_by_clauses}
                          name="order_by_clauses"
                          onChange={handleChange}
                        >
                          {filters
                            ? filters.order_by_clauses.map((sort) => {
                                return (
                                  <option key={sort} value={sort}>
                                    {sort}
                                  </option>
                                );
                              })
                            : ""}
                        </select>
                      </div>
                    </div>
                    <div className="right-box">
                      <button
                        className="xbtn sm filter-btn only-mob"
                        onClick={() => {
                          setIsFiltersSide(!isFiltersSide);
                        }}
                      >
                        Filter
                      </button>
                      <h3 className="total-num ">
                        Showing{" "}
                        <span className="fw-bold">
                          {(query.page ? query.page - 1 : 1) *
                            (query.page_size ? query.page_size : 0) +
                            1}
                          &nbsp;to&nbsp;
                          {(query.page ? query.page : 1) *
                            (query.page_size ? query.page_size : 0)}{" "}
                          of {productsRes.total_items}
                        </span>
                        &nbsp; items
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="products-box mt-3 pt-3">
                  {productsRes.items.length > 0 ? (
                    productsRes.items.map((product) => {
                      return <Product key={product.id} item={product} />;
                    })
                  ) : (
                    <NoResults />
                  )}
                </div>

                <ResultPagination
                  current_page={productsRes.current_page}
                  pages_count={productsRes.pages_count}
                  page_size={query.page_size}
                  total_items={productsRes.total_items}
                  setSearchParams={setSearchParams}
                  searchParams={searchParams}
                />
              </>
            ) : (
              <img src={imgLoading3} className="d-block m-auto" height="80" />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default SearchPage;
