import { useState } from "react";
import { Link } from "react-router-dom";
import OrderItemExpanded from "./OrderItemExpanded";

const OrderItem = (props) => {
  const [expanded, setExpanded] = useState(false);

  const getStatusProgress = () => {
    let progress = 100;
    let color = "$c1";

    switch (props.item.status) {
      case "PENDING":
      case "UN_PAID":
      case "PARTIALLY_PAID":
        progress = 10;
        color = "#f38910";
        break;

      case "PAID":
        progress = 60;
        color = "#f38910";
        break;

      case "FULL_FILLMENT":
        progress = 75;
        color = "#c76a00";
        break;

      case "SHIPPED":
        progress = 90;
        color = "#86cb6d";
        break;

      case "COMPLETED":
        progress = 100;
        color = "#00ba13";
        break;

      default:
        break;
    }

    return { progress, color };
  };

  return (
    <div className="order-body mb-4">
      <div className="order-box px-4 py-3">
        <div className="row justify-content-between">
          <div className="col-md-6">
            <div className="d-flex flex-column">
              <h2 className="order-num">{props.item.created_at}</h2>
              <h3 className="order-title">#{props.item.number}</h3>
            </div>
          </div>
          <div className="col-md-3">
            <div
              style={{
                width: "100px",
              }}
            >
              <div className="d-flex flex-column">
                <h2
                  className="order-title"
                  style={{
                    color: getStatusProgress().color,
                  }}
                >
                  {props.item.status}
                </h2>
                <h3
                  className="order-num"
                  style={{
                    color: "#999",
                  }}
                >
                  {getStatusProgress().progress}%
                </h3>
                <div className="progress">
                  <div
                    className="progress-bar-complete"
                    style={{
                      width: getStatusProgress().progress + "%",
                      background: getStatusProgress().color,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            {((props.item.payment_transaction &&
              props.item.payment_transaction.can_completed) ||
              props.item.payment_transaction == null) && (
              <>
                <h2 className="order-title">Next Step</h2>
                <Link to={`/order/${props.item.id}/pay`} className="xbtn sm">
                  Pay with Stripe
                </Link>
              </>
            )}
          </div>
        </div>
        <div>
          <span className="line-between my-3"></span>
          <div className="row">
            <div className="col-md-12">
              <h2 className="order-title">Delivery Address</h2>
              {props.item.address && (
                <p className="history-details">
                  {/* {props.item.address.address || ""},{" "} */}
                  {/* {props.item.address.build_number || ""},{" "} */}
                  {props.item.address.post_code || ""},{" "}
                  {props.item.address.city_name || ""},{" "}
                  {props.item.address.state_id || ""},{" "}
                  {props.item.address.country_id || ""}
                </p>
              )}
            </div>
          </div>
        </div>
        {!expanded && (
          <div className="drop-style">
            <button
              className="form-control input-style d-flex justify-content-between border-0"
              onClick={() => {
                setExpanded(true);
              }}
              type="button"
            >
              EXPAND
              <span>
                <svg
                  width="16"
                  height="10"
                  viewBox="0 0 16 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 10L0.205772 0.25L15.7942 0.25L8 10Z"
                    fill="#676767"
                  />
                </svg>
              </span>
            </button>
          </div>
        )}
        {expanded && (
          <OrderItemExpanded setExpanded={setExpanded} item={props.item} />
        )}
      </div>
    </div>
  );
};

export default OrderItem;
