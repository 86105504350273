import { useContext, useEffect, useState, createContext } from "react";
import AuthService from "../services/Auth.service";

const ThisContext = createContext();

export function useAuth() {
  return useContext(ThisContext);
}

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);

  useEffect(() => {
    const local_user = localStorage.getItem("user") ?? null;
    if (local_user) {
      setUser(JSON.parse(local_user));
    }
    let local_token = localStorage.getItem("token") ?? null;
    if (local_token) {
      setToken(local_token);
    }

    refreshUser();
  }, []);

  useEffect(() => {
    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
    }
  }, [user]);

  useEffect(() => {
    if (token) {
      localStorage.setItem("token", token);
    }
  }, [token]);

  const refreshUser = () => {
    AuthService.info().then((res) => {
      if (res && res.data && res.data.result) {
        setUser(res.data.result);
      }
    });
  };

  const getUser = () => {
    let u = localStorage.getItem("user");
    if (u) {
      return JSON.parse(u);
    }
    return null;
  };

  const isAuth = () => {
    return (
      localStorage.getItem("token") != null &&
      localStorage.getItem("user") != null
    );
  };

  const logout = async () => {
    await AuthService.logout();
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    setUser(null);
    setToken(null);
    document.location = "/";
  };

  return (
    <ThisContext.Provider
      value={{
        user,
        getUser,
        setUser,
        token,
        setToken,
        isAuth,
        logout,
        refreshUser,
      }}
    >
      {children}
    </ThisContext.Provider>
  );
};
