import { Link } from "react-router-dom";

function Category(props) {
  return (
    <>
      <Link
        to={
          props.item.categories.length > 0 && !props.goToSearch
            ? `/category/${props.item.id}`
            : `/search?category=${props.item.id}`
        }
        className="category-item p-2 text-center"
      >
        <img src={props.item.logo} className="w-100" alt="item" />
        <p className="total-num">
          {props.title ? props.title : props.item.name}
        </p>
      </Link>
    </>
  );
}

export default Category;
