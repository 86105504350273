import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";

function DealOfDay(props) {
  const [timer, setTimer] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  function daysBetween(date1, date2) {
    var one_day = 1000 * 60 * 60 * 24;
    var date1_ms = date1.getTime();
    var date2_ms = date2.getTime();
    var difference_ms = date2_ms - date1_ms;
    return Math.round(difference_ms / one_day);
  }

  function startCountdown() {
    var interval;
    interval = setInterval(function () {
      let newTimer = { ...timer };
      if (newTimer.seconds > 0) {
        newTimer.seconds--;
      } else {
        // Seconds is zero - check the minutes
        if (newTimer.minutes > 0) {
          newTimer.minutes--;
          newTimer.seconds = 59;
        } else {
          // Minutes is zero, check the hours
          if (newTimer.hours > 0) {
            newTimer.hours--;
            newTimer.minutes = 59;
            newTimer.seconds = 59;
          } else {
            // Hours is zero
            newTimer.days--;
            newTimer.hours = 23;
            newTimer.minutes = 59;
            newTimer.seconds = 59;
          }
        }
      }

      setTimer(newTimer);
    }, 1000);
  }

  useEffect(() => {
    if (!props.special) return;

    var targetDate = new Date(props.special.start_date);
    var now = new Date(props.special.end_date);

    let newTimer = { ...timer };

    newTimer.days = daysBetween(now, targetDate);
    var secondsLeft = props.special.remaining_time;
    newTimer.hours = Math.floor(secondsLeft / 60 / 60);
    secondsLeft = secondsLeft - newTimer.hours * 60 * 60;
    newTimer.minutes = Math.floor(secondsLeft / 60);
    secondsLeft = secondsLeft - newTimer.minutes * 60;
    newTimer.seconds = Math.floor(secondsLeft);
    setTimer(newTimer);
    startCountdown();
  }, [props.special]);

  return (
    <div className="deal-box-1">
      <div className="container">
        <div className="counter offer-box d-flex justify-content-between">
          {props.special ? (
            <div className="counter-wheel position-relative me-2">
              <div className="position-absolute red-btn-up">
                <button className="red-btn promo-btn">
                  {props.special.name}
                </button>
              </div>
              {props.products && props.products.length > 0 ? (
                <div className="row">
                  <div className="col-md-5 py-5">
                    <div className="w-img">
                      <img
                        src={
                          props.products[0].images &&
                          props.products[0].images.length > 0
                            ? props.products[0].images[0].path
                            : ""
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-7 py-5">
                    <h2 className="d-title">{props.products[0].name}</h2>
                    <p className="d-desc">{props.special.short_description}</p>
                    <span className="price-now me-2 mt-2">
                      ${props.products[0].variation.new_price}
                    </span>
                    <span className="old-price">
                      ${props.products[0].variation.price}
                    </span>
                    <p className="des mt-2">
                      {props.products[0].short_description}
                    </p>
                    <div className="d-flex align-content-around flex-wrap mt-3">
                      <div className="count-num">
                        <div className="content">
                          <span>{timer.days}</span>
                          <h3>DAYS</h3>
                        </div>
                      </div>
                      <div className="count-num">
                        <div className="content">
                          <span>{timer.hours}</span>
                          <h3>HOURS</h3>
                        </div>
                      </div>
                      <div className="count-num">
                        <div className="content">
                          <span>{timer.minutes}</span>
                          <h3>MINS</h3>
                        </div>
                      </div>
                      <div className="count-num">
                        <div className="content">
                          <span>{timer.seconds}</span>
                          <h3>SEC</h3>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex mt-3">
                      <Link
                        to={`/product/${props.products[0].id}`}
                        className="xbtn"
                      >
                        VIEW PRODUCT
                      </Link>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
          {props.promotion ? (
            <div className="deal-box-2">
              <div className="p-3 h-100">
                <h3>{props.promotion.name}</h3>
                <div>
                  <div className="tool1">
                    <img
                      width={150}
                      height={150}
                      style={{ maxWidth: "80%", objectFit: "contain" }}
                      className="d-block"
                      src={props.promotion.image}
                    />
                  </div>
                </div>
                <p className="d-desc">{props.promotion.short_description}</p>
                <Link to="/search" className="xbtn">
                  SHOP NOW
                </Link>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default DealOfDay;
