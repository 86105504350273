import { toast } from 'react-toastify';

export const toast_error = (text, options = {}) => {
  toast(text, {
    type: 'error',
    position: 'top-center',
    ...options,
  });
};

export const toast_success = (text, options = {}) => {
  toast(text, {
    type: 'success',
    position: 'top-center',
    ...options,
  });
};
