import { useEffect, useState } from "react";
import DealOfDay from "../../components/DealOfDay";
import FeaturedBrands from "../../components/FeaturedBrands";
import FindForm from "../../components/FindForm";
import HomeSlider from "../../components/HomeSlider";
import Offers from "../../components/Offers";
import ProductSlider from "../../components/ProductSlider";
import SubscribeEmail from "../../components/SubscribeEmail";
import ProductService from "../../services/Product.service";
import PromotionService from "../../services/Promotion.service";
import BrandsSlider from "../../components/BrandsSlider/BrandsSlider";
import HeroSection from "../../components/HeroSection/HeroSection";

function HomePage() {
  const [slider1Items, setSlider1Items] = useState([]);
  const [slider2Items, setSlider2Items] = useState([]);
  const [promotions, setPromotions] = useState(null);

  useEffect(() => {
    ProductService.list({ page_size: 10, OrderByRandom: "OrderByRandom" }).then(
      (res) => {
        setSlider1Items(res.data.result.items);
      }
    );

    ProductService.list({ page_size: 10, OrderByRandom: "OrderByRandom" }).then(
      (res) => {
        setSlider2Items(res.data.result.items);
      }
    );

    PromotionService.list().then((res) => {
      setPromotions(res.data.result);
    });
  }, []);

  return (
    <>
      <HeroSection />
      <BrandsSlider />
      <div className="container">
        <ProductSlider items={slider1Items} header="Featured Parts" />
        <br />

        <ProductSlider
          items={slider2Items}
          header="Special Products"
          className="mt-5"
        />
      </div>
      <br />
      {promotions ? (
        <>
          <DealOfDay
            special={promotions.special_promotion}
            products={promotions.products}
            promotion={
              promotions && promotions.featured_promotions.length > 0
                ? promotions.featured_promotions[0]
                : null
            }
          />
          <Offers
            promotions={
              promotions && promotions.featured_promotions.length > 1
                ? [...promotions.featured_promotions].slice(1)
                : []
            }
          />
        </>
      ) : (
        ""
      )}

      <div className="container">
        <br />
        <ProductSlider header="Best Seller" items={slider2Items} />
        <br />
      </div>
    </>
  );
}

export default HomePage;
