import { useContext, useEffect, useState, createContext } from "react";
import config from "../config/config";
import WishlistService from "../services/Wishlist.service";
import { useAuth } from "./Auth.context";

const ThisContext = createContext();

export function useWishlist() {
  return useContext(ThisContext);
}

export const WishlistProvider = ({ children }) => {
  const [wishlist, setWishlist] = useState(null);
  const { isAuth } = useAuth();

  useEffect(() => {
    if (isAuth()) refreshWishlist();
  }, [isAuth]);

  const refreshWishlist = async () => {
    if (!isAuth()) return;
    const res = await WishlistService.list();
    setWishlist(res.data.result);
  };

  const addRemoveWishlist = async (product_id, variation_id, quantity = 1) => {
    const founded = getInWishlist(product_id, variation_id);
    let res;
    if (founded) {
      res = await WishlistService.remove(founded.id);
    } else {
      res = await WishlistService.post({
        product_id: product_id,
        variation_id: variation_id,
        quantity: quantity,
        branch_id: config.defaultBranchId,
        company_id: config.defaultCompanyId,
      });
    }
    if (res) {
      setWishlist(res.data.result);
    }
    return res;
  };

  const removeFromWishlist = async (id) => {
    const res = await WishlistService.remove(id);
    if (res) {
      setWishlist(res.data.result);
    }
    return res;
  };

  const getInWishlist = (product_id, variant_id) => {
    if (!wishlist || !wishlist.items) return false;
    const founded = wishlist.items.filter(
      (w) => w.product_id == product_id && w.variation_id == variant_id
    );

    if (founded && founded.length > 0) return founded[0];

    return false;
  };

  return (
    <ThisContext.Provider
      value={{
        wishlist,
        addRemoveWishlist,
        refreshWishlist,
        removeFromWishlist,
        getInWishlist,
      }}
    >
      {children}
    </ThisContext.Provider>
  );
};
