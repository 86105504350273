import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import FindForm from "../../components/FindForm";
import ProductService from "../../services/Product.service";
import { goTo } from "../../utils/GeneralHelpers";
import { imgCartAdd, imgCheck, imgLoading3, imgX } from "../../utils/Images";
import ImagesSlider from "./ImagesSlider";
import parse from "html-react-parser";
import config from "../../config/config";
import { useCart } from "../../contexts/Cart.context";
import { getAvailable } from "../../utils/ProjectHelpers";
import { useWishlist } from "../../contexts/Wishlist.context";
import { useGlobal } from "../../contexts/Global.context";
import { useAuth } from "../../contexts/Auth.context";
import AddedToCart from "../../components/modals/AddedToCart.modal";

const ProductDetails = () => {
  const [product, setProduct] = useState(null);
  const [variations, setVariations] = useState(null);
  const [fits, setFits] = useState(null);
  const [isPostingCart, setIsPostingCart] = useState(false);
  const [isPostingWishlist, setIsPostingWishlist] = useState(false);
  const [available, setAvailable] = useState(null);
  const [showAddedToCartModal, setShowAddedToCartModal] = useState(false);

  const { id } = useParams();
  const { addToCart, cart } = useCart();
  const { addRemoveWishlist, getInWishlist } = useWishlist();
  const { showModal } = useGlobal();
  const { isAuth } = useAuth();
  const [cartItem, setCartItem] = useState({
    type: 1,
    product_id: null,
    variation_id: null,
    company_id: config.defaultCompanyId,
    branch_id: config.defaultCompanyId,
    quantity: 1,
    modifierGroup: [],
  });

  useEffect(() => {
    if (!id) return;
    ProductService.one(id).then((res) => {
      setProduct(res.data.result.product);
      setVariations(res.data.result.variations);
      goTo("#header");
    });

    ProductService.fitments(id, { page_size: 300 }).then((res) => {
      setFits(res.data.result.items);
    });
  }, [id]);

  useEffect(() => {
    setCartItem({
      ...cartItem,
      product_id: product ? product.id : null,
      variation_id: variations ? variations[0].id : null,
    });

    if (product && variations) {
      setAvailable(getAvailable(product, variations[0]));
    }
  }, [product, variations]);

  const handleAddQty = (e) => {
    if (isPostingCart) return;
    let qty = cartItem.quantity + 1;
    setCartItem({
      ...cartItem,
      quantity: qty,
    });
  };

  const handleMinusQty = (e) => {
    if (isPostingCart) return;
    let qty = cartItem.quantity - 1;
    if (qty <= 0) qty = 1;
    setCartItem({
      ...cartItem,
      quantity: qty,
    });
  };

  const handleAddToCart = async () => {
    if (!isAuth()) {
      showModal("goLogin");
      return;
    }
    if (isPostingCart) return;
    setIsPostingCart(true);
    const res = await addToCart(cartItem);
    setIsPostingCart(false);
    setShowAddedToCartModal(true);
  };

  const handleWishlistBtn = async () => {
    if (!isAuth()) {
      showModal("goLogin");
      return;
    }
    if (isPostingWishlist) return;
    setIsPostingWishlist(true);
    const res = await addRemoveWishlist(
      cartItem.product_id,
      cartItem.variation_id
    );
    setIsPostingWishlist(false);
  };

  return product && variations ? (
    <>
      <div className="container product-details-box">
        {isAuth && cart ? (
          <AddedToCart
            cartItem={cartItem}
            product={product}
            variation={variations[0]}
            show={showAddedToCartModal}
            setShow={setShowAddedToCartModal}
          />
        ) : (
          ""
        )}

        <div className="row my-5">
          <div className="col-md-6">
            <div className="imgs-slider-parent">
              <ImagesSlider items={product ? product.images : null} />
            </div>
          </div>
          <div className="col-md-6">
            <h2 id="header" className="brand">
              {product && product.brand ? product.brand.name : "No Brand"}
            </h2>
            <h1 className="title">{product ? product.name : ""}</h1>
            {variations && (
              <>
                <span className="price">${variations[0].new_price}</span>
                {variations[0].new_price != variations[0].price && (
                  <>
                    <span className="px-2 fw-bold text-decoration-line-through fs-5">
                      ${variations[0].price}
                    </span>
                  </>
                )}
              </>
            )}
            <span className="line-between my-4"></span>
            <table>
              <tr>
                <td style={{ minWidth: "150px" }}>
                  <b>Status</b>
                </td>
                <td>
                  {available && available.max > 0 ? (
                    <>
                      <img src={imgCheck} height={19} /> In stock
                    </>
                  ) : (
                    <>
                      <img src={imgX} height={19} /> Out of stock
                    </>
                  )}
                </td>
              </tr>
              {available && available.max != 9999 ? (
                <tr>
                  <td>
                    <b>Available</b>
                  </td>
                  <td>{available.max} items</td>
                </tr>
              ) : (
                ""
              )}
              {product.categories ? (
                <tr>
                  <td>
                    <b>Category</b>
                  </td>
                  <td>
                    {product.categories.map((c) => {
                      return `${c.name}, `;
                    })}
                    {product.categories.length == 0 && "No Category"}
                  </td>
                </tr>
              ) : (
                ""
              )}
              {product.mpn ? (
                <tr>
                  <td>
                    <b>mpn</b>
                  </td>
                  <td>{product.mpn}</td>
                </tr>
              ) : (
                ""
              )}
              {product.epid ? (
                <tr>
                  <td>
                    <b>epid</b>
                  </td>
                  <td>{product.epid}</td>
                </tr>
              ) : (
                ""
              )}
              {product.oem_pn ? (
                <tr>
                  <td>
                    <b>oem_pn</b>
                  </td>
                  <td>{product.oem_pn}</td>
                </tr>
              ) : (
                ""
              )}
            </table>

            {/* <span className="line-between my-4"></span>
            <div className="d-flex align-items-center">
              <span className="me-2">
                <svg
                  width="60"
                  height="60"
                  viewBox="0 0 60 60"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="30" cy="30" r="30" fill="$c1" />
                  <g clipPath="url(#clip0_175_9269)">
                    <path
                      d="M49.0849 24.4062H44.5245L43.257 21.2375C41.9544 17.9796 38.8459 15.875 35.3366 15.875H25.6628C22.1543 15.875 19.045 17.9796 17.7417 21.2375L16.4742 24.4062H11.9145C11.3196 24.4062 10.8832 24.9654 11.0279 25.542L11.4849 27.3701C11.5862 27.7769 11.9519 28.0625 12.3716 28.0625H13.9003C12.8774 28.956 12.2185 30.2547 12.2185 31.7188V35.375C12.2185 36.6029 12.6877 37.7112 13.4372 38.5689V42.6875C13.4372 44.0335 14.5288 45.125 15.8747 45.125H18.3122C19.6582 45.125 20.7497 44.0335 20.7497 42.6875V40.25H40.2497V42.6875C40.2497 44.0335 41.3413 45.125 42.6872 45.125H45.1247C46.4707 45.125 47.5622 44.0335 47.5622 42.6875V38.5689C48.3118 37.712 48.781 36.6037 48.781 35.375V31.7188C48.781 30.2547 48.1221 28.956 47.0999 28.0625H48.6286C49.0483 28.0625 49.414 27.7769 49.5153 27.3701L49.9723 25.542C50.1163 24.9654 49.6798 24.4062 49.0849 24.4062ZM22.2678 23.0481C22.8231 21.6603 24.1676 20.75 25.6628 20.75H35.3366C36.8319 20.75 38.1763 21.6603 38.7316 23.0481L40.2497 26.8438H20.7497L22.2678 23.0481ZM18.3122 35.3598C16.8497 35.3598 15.8747 34.3878 15.8747 32.9299C15.8747 31.472 16.8497 30.5 18.3122 30.5C19.7747 30.5 21.9685 32.6869 21.9685 34.1448C21.9685 35.6028 19.7747 35.3598 18.3122 35.3598ZM42.6872 35.3598C41.2247 35.3598 39.031 35.6028 39.031 34.1448C39.031 32.6869 41.2247 30.5 42.6872 30.5C44.1497 30.5 45.1247 31.472 45.1247 32.9299C45.1247 34.3878 44.1497 35.3598 42.6872 35.3598Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_175_9269">
                      <rect
                        width="39"
                        height="39"
                        fill="white"
                        transform="translate(11 11)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </span>
              <div>
                <h3 className="order-font fw-bold">
                  Vehicle Info Required to Guarantee Fit
                </h3>
                <button
                  onClick={() => {
                    goTo("#find-form");
                  }}
                  type="button"
                  className="add-vehicle"
                >
                  Add Vehicle
                </button>
              </div>
            </div> */}
            <span className="line-between my-4"></span>

            <div className="d-flex justify-content-start align-items-center col-only-mob">
              {available && available.max > 0 ? (
                <>
                  <div className="d-flex justify-content-between align-items-center number">
                    <button className="minus" onClick={handleMinusQty}>
                      <svg
                        width="9"
                        height="4"
                        viewBox="0 0 9 4"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.50192 0.947266H0V3.07391H8.50192V0.947266Z"
                          fill="white"
                        />
                      </svg>
                    </button>
                    <span>{cartItem.quantity}</span>
                    <button className="plus" onClick={handleAddQty}>
                      <svg
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.14447 3.94684H0.642578V6.07349H9.14447V3.94684Z"
                          fill="white"
                        />
                        <path
                          d="M5.95605 9.26349L5.95605 0.756836L3.83057 0.756836L3.83057 9.26349H5.95605Z"
                          fill="white"
                        />
                      </svg>
                    </button>
                  </div>
                  <button
                    onClick={handleAddToCart}
                    className={`xbtn d-flex justify-content-between align-items-center mx-5 ${
                      isPostingCart ? "is-loading" : ""
                    }`}
                  >
                    <img src={imgCartAdd} className="me-3" />
                    Add to cart
                  </button>
                </>
              ) : (
                ""
              )}
              <button
                className={`heart p-1 mx-3 ${
                  isPostingWishlist ? "is-loading" : ""
                } ${
                  getInWishlist(product.id, variations[0].id) ? "active" : ""
                }`}
                onClick={handleWishlistBtn}
              >
                <svg
                  width="28"
                  height="29"
                  viewBox="0 0 28 29"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.2246 10.9852C20.989 10.4396 20.6493 9.94523 20.2244 9.5297C19.7993 9.11293 19.298 8.78173 18.7479 8.55411C18.1774 8.31715 17.5656 8.19586 16.9479 8.19728C16.0813 8.19728 15.2357 8.43458 14.501 8.88282C14.3252 8.99005 14.1582 9.10782 14 9.23615C13.8418 9.10782 13.6748 8.99005 13.499 8.88282C12.7643 8.43458 11.9187 8.19728 11.0521 8.19728C10.4281 8.19728 9.82344 8.31681 9.25215 8.55411C8.7002 8.78263 8.20273 9.11134 7.77559 9.5297C7.35019 9.94476 7.01037 10.4393 6.77539 10.9852C6.53105 11.5529 6.40625 12.1559 6.40625 12.7764C6.40625 13.3617 6.52578 13.9717 6.76309 14.5922C6.96172 15.1108 7.24648 15.6486 7.61035 16.1918C8.18691 17.0514 8.97969 17.9479 9.96406 18.8567C11.5953 20.3631 13.2107 21.4037 13.2793 21.4459L13.6959 21.7131C13.8805 21.8309 14.1178 21.8309 14.3023 21.7131L14.7189 21.4459C14.7875 21.402 16.4012 20.3631 18.0342 18.8567C19.0186 17.9479 19.8113 17.0514 20.3879 16.1918C20.7518 15.6486 21.0383 15.1108 21.2352 14.5922C21.4725 13.9717 21.592 13.3617 21.592 12.7764C21.5938 12.1559 21.4689 11.5529 21.2246 10.9852ZM14 20.3227C14 20.3227 7.74219 16.3131 7.74219 12.7764C7.74219 10.9852 9.22402 9.53322 11.0521 9.53322C12.3371 9.53322 13.4516 10.2504 14 11.2981C14.5484 10.2504 15.6629 9.53322 16.9479 9.53322C18.776 9.53322 20.2578 10.9852 20.2578 12.7764C20.2578 16.3131 14 20.3227 14 20.3227Z"
                    fill="#777777"
                  />
                </svg>
              </button>
              {/* <button className="btn-submit-card width-btn">Buy Now</button> */}
            </div>
          </div>
        </div>

        {/* <!--productinfo--> */}
        <div className="product-info-box my-5">
          <h3 className="order-font fw-bold p-4 py-2 pt-3">
            Product Infomation
          </h3>
          <span className="line-between2 position-relative w-100">
            <span className="black-line position-absolute ms-4"></span>
          </span>
          <div className="p-4 des-style w-100">
            {product && product.description ? parse(product.description) : ""}
          </div>
        </div>

        <div className="product-info-box my-5">
          <h3 className="order-font fw-bold p-4 py-2 pt-3">
            Vehicle Fitment Information
          </h3>
          <span className="line-between2 position-relative w-100">
            <span className="black-line position-absolute ms-4"></span>
          </span>
          <div className="p-4 des-style w-100 product-table">
            <table className="table text-center">
              <tbody>
                <tr id="head-table">
                  <td>Car Make</td>
                  <td>Model Name</td>
                  <td>Model Year</td>
                  <td>Trim</td>
                  <td>Engine</td>
                </tr>
                {fits &&
                  fits.map((fit) => {
                    return (
                      <tr key={fit.id}>
                        <td>{fit.car_make ? fit.car_make.name : ""}</td>
                        <td>{fit.car_model ? fit.car_model.name : ""}</td>
                        <td>{fit.car_year ? fit.car_year.year : ""}</td>
                        <td>{fit.car_trim ? fit.car_trim.title : ""}</td>
                        <td>{fit.car_engine ? fit.car_engine.title : ""}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  ) : (
    <img src={imgLoading3} className="d-block m-auto" height="80" />
  );
};

export default ProductDetails;
