import config from "../config/config";
import apiWorker from "../utils/Api";

const root = `${config.root}/products`;

const list = async (search = {}) => {
  if (!search.brand) delete search.brand;
  if (!search.text) delete search.text;
  if (!search.car_make) delete search.car_make;
  if (!search.car_model) delete search.car_model;
  if (!search.car_year) delete search.car_year;
  if (!search.page_size) search.page_size = 15;

  try {
    const res = await apiWorker().get(root, {
      params: search,
    });
    return res;
  } catch (error) {
    return false;
  }
};

const one = async (id) => {
  try {
    const res = await apiWorker().get(root + "/" + id);
    return res;
  } catch (error) {
    return false;
  }
};

const fitments = async (id, params = {}) => {
  try {
    const res = await apiWorker().get(
      root + "/" + id + "/fit-with-compinations",
      {
        params: params,
      }
    );
    return res;
  } catch (error) {
    return false;
  }
};

const filters = async () => {
  try {
    const res = await apiWorker().get(root + "/filters");
    return res;
  } catch (error) {
    return false;
  }
};

export default { list, filters, one, fitments };
