import { Link } from "react-router-dom";

const OrderPayedCompletePage = () => {
  return (
    <>
      <div className="py-5 order-body text-center m-auto">
        <span className="d-block mb-3">
          <svg
            width="60"
            height="60"
            viewBox="0 0 60 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_160_5836)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 30C0 22.0435 3.16071 14.4129 8.7868 8.7868C14.4129 3.16071 22.0435 0 30 0C37.9565 0 45.5871 3.16071 51.2132 8.7868C56.8393 14.4129 60 22.0435 60 30C60 37.9565 56.8393 45.5871 51.2132 51.2132C45.5871 56.8393 37.9565 60 30 60C22.0435 60 14.4129 56.8393 8.7868 51.2132C3.16071 45.5871 0 37.9565 0 30H0ZM28.288 42.84L45.56 21.248L42.44 18.752L27.712 37.156L17.28 28.464L14.72 31.536L28.288 42.844V42.84Z"
                fill="#4ECB71"
              />
            </g>
            <defs>
              <clipPath id="clip0_160_5836">
                <rect width="60" height="60" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </span>
        <h2 className="title">Thank You!</h2>
        <h2 className="title">Your order has been received</h2>
        <p className="map">
          We’ll email you an order confirmation with details and tracking info.
        </p>
        <div className="text-center">
          <Link className="btn btn-submit" to="/profile/orders">My Orders</Link>
        </div>
      </div>
    </>
  );
};

export default OrderPayedCompletePage;
