import { imgPart2 } from "../utils/Images";

function SubscribeEmail() {
  return (
    <>
      {/* <!--offers--> */}
      <div className="offers p-3 my-5">
        <div className="d-flex">
          <div className="w-50">
            <h1>SPECIAL OFFERS</h1>
            <h3>FOR WHOLESALERS</h3>
            <h4>Sign up and ask for a free quotaion</h4>
            <form className="d-flex">
              <input
                className="inputemail px-3 me-3"
                type="text"
                placeholder="Email Address"
              />
              <button className="gray-btn">SUBMIT</button>
            </form>
          </div>
          <div className="position-relative w-50">
            <div className="position-absolute buildimg">
              <img src={imgPart2} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SubscribeEmail;
