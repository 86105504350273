import { useState } from "react";
import { Link } from "react-router-dom";
import config from "../config/config";
import { useAuth } from "../contexts/Auth.context";
import { useCart } from "../contexts/Cart.context";
import { useGlobal } from "../contexts/Global.context";
import { useWishlist } from "../contexts/Wishlist.context";
import { imgCartAdd, thumb } from "../utils/Images";
import { toast_success } from "../utils/messages";

const Product = (props) => {
  const [isPostingCart, setIsPostingCart] = useState(false);
  const [isPostingWishlist, setIsPostingWishlist] = useState(false);
  const { addToCart } = useCart();
  const { isAuth } = useAuth();
  const { showModal } = useGlobal();
  const { addRemoveWishlist, getInWishlist } = useWishlist();

  let img = props.item.images.filter((i) => i.is_default == 1);
  if (img.length > 0) img = img[0].path;
  else if (props.item.images.length > 0) img = props.item.images[0].path;
  else img = thumb;

  const handleAddToCart = async () => {
    if (!isAuth()) {
      showModal("goLogin");
      return;
    }

    if (isPostingCart) return;
    setIsPostingCart(true);
    const res = await addToCart({
      type: 1,
      product_id: props.item.id,
      variation_id: props.item.variation.id,
      company_id: config.defaultCompanyId,
      branch_id: config.defaultCompanyId,
      quantity: 1,
      modifierGroup: [],
    });
    toast_success("Product added to cart successfully");
    setIsPostingCart(false);
  };

  const handleWishlistBtn = async () => {
    if (!isAuth()) {
      showModal("goLogin");
      return;
    }
    if (isPostingWishlist) return;
    setIsPostingWishlist(true);
    const res = await addRemoveWishlist(props.item.id, props.item.variation.id);
    setIsPostingWishlist(false);
  };

  const isInStock = () => {
    return (
      props.item.variation.in_stock == 1 &&
      props.item.variation.available_quantity > 0
    );
  };

  return (
    <>
      <div className="product-item">
        <Link className="product-img" to={`/product/${props.item.id}`}>
          <img src={img} />
        </Link>
        <div className="product-body">
          <Link to={`/product/${props.item.id}`}>
            <span className="product-brand">
              {props.item.brand ? props.item.brand.name : ""}
            </span>
            <span className="product-des">{props.item.name}</span>
          </Link>
        </div>

        <div className="product-bottom">
          <div className="left">
            {isInStock() ? (
              <div className="price">${props.item.variation.price}</div>
            ) : (
              <div className="out-of-stock">Out of Stock</div>
            )}
          </div>

          <div className="right">
            <button
              onClick={handleWishlistBtn}
              className={`fav-btn ${isPostingWishlist ? "is-loading" : ""} ${
                getInWishlist(props.item.id, props.item.variation.id)
                  ? "active"
                  : ""
              }`}
            >
              <svg
                width="22"
                height="20"
                viewBox="0 0 22 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 1C3.239 1 1 3.216 1 5.95C1 8.157 1.875 13.395 10.488 18.69C10.6423 18.7839 10.8194 18.8335 11 18.8335C11.1806 18.8335 11.3577 18.7839 11.512 18.69C20.125 13.395 21 8.157 21 5.95C21 3.216 18.761 1 16 1C13.239 1 11 4 11 4C11 4 8.761 1 6 1Z"
                  stroke="#8F8F8F"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>

            {
              <button
                type="button"
                onClick={handleAddToCart}
                className={`xbtn xbtn-circle ${
                  isPostingCart ? "is-loading" : ""
                }`}
                disabled={!isInStock()}
              >
                <img src={imgCartAdd} />
              </button>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default Product;
