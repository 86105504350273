import { useEffect, useState } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import { useAuth } from "../../contexts/Auth.context";
import authService from "../../services/Auth.service";
import FormHandler from "../../utils/Handlers";
import { setFormLoading } from "../../utils/UiHelpers";
import parse from "html-react-parser";
import { Link, useNavigate } from "react-router-dom";
import GlobalService from "../../services/Global.service";
import PageTitle from "../../components/PageTitle";

function BusinessRegisterPage() {
  const navigate = useNavigate();
  const { setUser, setToken } = useAuth();
  const [form, setForm] = useState({
    email: "",
    password: "",
    confirm_password: "",
    is_agreed: "",
    first_name: "",
    last_name: "",
    country_id: "",
    state_id: "",
    city_id: "",
    post_code: "",
  });
  const formHandler = new FormHandler(form, setForm);

  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState([-1, -1, -1]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    setIsLoading([1, -1, -1]);
    GlobalService.countries().then((res) => {
      setCountries(res.data.result);
      setIsLoading([0, 0, 0]);
    });
  }, []);

  useEffect(() => {
    setError("");
  }, [form]);

  const handleCSCChange = async (e) => {
    let newForm = { ...form };
    switch (e.target.name) {
      case "country_id":
        newForm.country_id = e.target.value;
        newForm.state_id = null;
        newForm.city_id = null;
        setIsLoading([-1, 1, -1]);
        const mres = await GlobalService.states(e.target.value);
        setCities([]);
        setStates(mres.data.result);
        break;

      case "state_id":
        newForm.state_id = e.target.value;
        newForm.city_id = null;
        setIsLoading([-1, -1, 1]);
        const yres = await GlobalService.cities(e.target.value);
        setCities(yres.data.result);
        break;

      case "city_id":
        newForm.city_id = e.target.value;
        break;
    }

    setIsLoading([0, 0, 0]);
    setForm({
      ...newForm,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    if (form.password != form.confirm_password) {
      setError("Confirm password not match");
      return;
    }
    setFormLoading(e.target);
    let res = await authService.registerAsBuseniss(form);
    if (res && res.data) {
      if (res.data.success == true) {
        alert(res.data.message);
        navigate("/login");
      } else {
        setError(res.data.message);
      }
    } else {
      if (res.response && res.response.data && res.response.data.message) {
        const data = res.response.data;
        let error = data.message;

        if (data.errors) {
          Object.keys(data.errors).forEach((err) => {
            data.errors[err].forEach((str_er) => {
              error += "<br />" + str_er;
            });
          });
        }

        setError(error);
      } else {
        setError("Error");
      }
    }
    setFormLoading(e.target, false);
  };

  return (
    <>
      <div className="container">
        <br />
        <br />
        <PageTitle text="Create Business Account" />
        <div className="pb-5 pt-3 login-box m-auto">
          <form onSubmit={handleSubmit}>
            <span className="text-danger text-start d-block fw-bold mb-3">
              {parse(error)}
            </span>

            <div className="row">
              <div className="col-md-6">
                <label className="xlabel">
                  First Name
                  <span className="position-absolute translate-middle p-2 ms-2 icon">
                    *
                  </span>
                </label>
                <input
                  autoFocus
                  required
                  name="first_name"
                  type="text"
                  className="xinput"
                  value={form.first_name}
                  onChange={formHandler.handleTextChange}
                />
              </div>

              <div className="col-md-6">
                <label className="xlabel">
                  Last Name
                  <span className="position-absolute translate-middle p-2 ms-2 icon">
                    *
                  </span>
                </label>
                <input
                  required
                  name="last_name"
                  type="text"
                  className="xinput"
                  value={form.last_name}
                  onChange={formHandler.handleTextChange}
                />
              </div>
            </div>

            <label className="xlabel">
              Email Address
              <span className="position-absolute translate-middle p-2 ms-2 icon">
                *
              </span>
            </label>
            <input
              required
              name="email"
              type="email"
              className="xinput"
              value={form.email}
              onChange={formHandler.handleTextChange}
            />

            <label className="xlabel">
              Tax ID
              <span className="position-absolute translate-middle p-2 ms-2 icon">
                *
              </span>
            </label>
            <input
              required
              name="tax_id"
              type="text"
              className="xinput"
              value={form.tax_id}
              onChange={formHandler.handleTextChange}
            />

            <label className="xlabel">
              Company Name
              <span className="position-absolute translate-middle p-2 ms-2 icon">
                *
              </span>
            </label>
            <input
              required
              name="company_name"
              type="text"
              className="xinput"
              value={form.company_name}
              onChange={formHandler.handleTextChange}
            />

            <div className="row">
              <div className="col-md-6">
                <label className="xlabel">
                  Country
                  <span className="position-absolute translate-middle p-2 ms-2 icon">
                    *
                  </span>
                </label>
                <select
                  required
                  name="country_id"
                  value={form.country_id}
                  onChange={handleCSCChange}
                  className={`xinput ${isLoading[0] == 1 ? "is-loading" : ""}`}
                  disabled={isLoading[0] == -1}
                >
                  <option hidden value="">
                    Select Country
                  </option>
                  {countries
                    ? countries.map((c) => {
                        return (
                          <option key={c.id} value={c.id}>
                            {c.name}
                          </option>
                        );
                      })
                    : ""}
                </select>
              </div>

              <div className="col-md-6">
                <label className="xlabel">
                  State
                  <span className="position-absolute translate-middle p-2 ms-2 icon">
                    *
                  </span>
                </label>
                <select
                  required
                  name="state_id"
                  value={form.state_id}
                  onChange={handleCSCChange}
                  className={`xinput ${isLoading[1] == 1 ? "is-loading" : ""}`}
                  disabled={isLoading[1] == -1}
                >
                  <option value="" hidden>
                    Select State
                  </option>
                  {states
                    ? states.map((c) => {
                        return (
                          <option key={c.id} value={c.id}>
                            {c.name}
                          </option>
                        );
                      })
                    : ""}
                </select>
              </div>

              <div className="col-md-6">
                <label className="xlabel">
                  City
                  <span className="position-absolute translate-middle p-2 ms-2 icon">
                    *
                  </span>
                </label>
                <select
                  required
                  name="city_id"
                  value={form.city_id}
                  onChange={handleCSCChange}
                  className={`xinput ${isLoading[2] == 1 ? "is-loading" : ""}`}
                  disabled={isLoading[2] == -1}
                >
                  <option value="" hidden>
                    Select City
                  </option>
                  {cities
                    ? cities.map((c) => {
                        return (
                          <option key={c.id} value={c.id}>
                            {c.name}
                          </option>
                        );
                      })
                    : ""}
                </select>
              </div>

              <div className="col-md-6">
                <label className="xlabel">
                  Post Code
                  <span className="position-absolute translate-middle p-2 ms-2 icon">
                    *
                  </span>
                </label>
                <input
                  required
                  name="post_code"
                  type="number"
                  className="xinput"
                  value={form.post_code}
                  onChange={formHandler.handleTextChange}
                />
              </div>
            </div>

            <label className="xlabel">
              Password
              <span className="position-absolute translate-middle p-2 ms-2 icon">
                *
              </span>
            </label>
            <input
              required
              name="password"
              type="password"
              className="xinput"
              value={form.password}
              onChange={formHandler.handleTextChange}
            />

            <label className="xlabel">
              Confirm Password
              <span className="position-absolute translate-middle p-2 ms-2 icon">
                *
              </span>
            </label>
            <input
              required
              name="confirm_password"
              type="password"
              className="xinput"
              value={form.confirm_password}
              onChange={formHandler.handleTextChange}
            />

            <label className="xlabel">
              Attach Your TAX ID Document
              <span className="position-absolute translate-middle p-2 ms-2 icon">
                *
              </span>
            </label>
            <input
              required
              name="tax_id_document"
              type="file"
              className="xinput"
              value={form.tax_id_document}
              onChange={formHandler.tax_id_document}
            />

            <div className="mb-3 d-flex">
              <label className="form-check ">
                <input
                  required
                  name="is_agreed"
                  className="form-check-input"
                  type="checkbox"
                  checked={form.is_agreed}
                  onChange={formHandler.handleTextChange}
                />
                <span className="agree">
                  By creating an account, you agree to our
                  <Link to="/terms-conditions">
                    &nbsp;Terms & Conditions&nbsp;
                  </Link>
                  and
                  <Link to="/privacy-policy">&nbsp;Privacy Policy&nbsp;</Link>
                </span>
              </label>
            </div>

            <div className="text-center">
              <button disabled={!form.is_agreed} type="submit" className="xbtn">
                CREATE ACCOUNT
              </button>
              <br />
              <hr className="mt-5" />
              <Link to="/register" className="btn btn2 border-0 mt-2 font-bold">
                CREATE INDIVIDUAL ACCOUNT
              </Link>
              <div className="new-account">
                Already have an account?
                <Link to="/login"> Sign In</Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default BusinessRegisterPage;
