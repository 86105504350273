import { useEffect, useState } from "react";
import { imgLogo, imgSearch } from "../utils/Images";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CategoriesDrop from "../layout/Navbar/CategoriesDrop";
import { useGlobal } from "../contexts/Global.context";
import { useAuth } from "../contexts/Auth.context";

const SideNav = () => {
  const [searchText, setSearchText] = useState(null);
  const navigate = useNavigate();
  const { isSideNav, setIsSideNav } = useGlobal();
  const { user, logout } = useAuth();
  const location = useLocation();

  useEffect(() => {
    setIsSideNav(false);
  }, [location]);

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchText) {
      navigate(`search?text=${searchText}`);
      setSearchText(null);
    }
  };

  const handleOpenClose = () => {
    setIsSideNav(!isSideNav);
  };

  const handleLogout = async () => {
    await logout();
  };

  return (
    <div className={`side-nav-box ${isSideNav ? "opened" : ""}`}>
      <nav className="side-nav">
        <div className="top">
          <img className="logo" src={imgLogo} />

          <form className="search-form" onSubmit={handleSearch}>
            <input
              placeholder="Search for parts..."
              value={searchText}
              type="search"
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
            <button>
              <img src={imgSearch} />
            </button>
          </form>
        </div>

        <div className="categories">
          <CategoriesDrop />
        </div>

        <div className="links">
          <h3>Site Map</h3>
          <ul>
            <li>
              <Link to={"/"}>Home</Link>
            </li>
            <li>
              <Link to={"/search?text="}>Products</Link>
            </li>
            <li>
              <Link to={"/about-us"}>About Us</Link>
            </li>
            <li>
              <Link to={"/privacy-policy"}>Privacy Policy</Link>
            </li>
            <li>
              <Link to={"/terms-conditions"}>Terms & Conditions</Link>
            </li>
            <li>
              <Link to={"/return-policy"}>Return Policy</Link>
            </li>
            {user && (
              <li>
                <Link to={"/"} onClick={handleLogout}>
                  Logout
                </Link>
              </li>
            )}
          </ul>
        </div>
      </nav>
      <div className="side-back-filter" onClick={handleOpenClose}></div>
    </div>
  );
};

export default SideNav;
