import {
  imgBrand1,
  imgBrand2,
  imgBrand3,
  imgBrand4,
  imgBrand5,
  imgBrand6,
  imgBrand7,
} from "../../utils/Images";
import "./BrandsSlider.scss";

const BrandsSlider = () => {
  const images = [
    imgBrand1,
    imgBrand2,
    imgBrand3,
    imgBrand4,
    imgBrand5,
    imgBrand6,
    imgBrand1,
    imgBrand2,
    imgBrand3,
    imgBrand4,
    imgBrand5,
    imgBrand6,
    imgBrand7,
    imgBrand1,
    imgBrand2,
    imgBrand3,
    imgBrand4,
    imgBrand5,
    imgBrand6,
    imgBrand7,
  ];
  return (
    <div className="brands-slider">
      <div className="b-slide-track">
        {images.map((item, i) => {
          return (
            <div key={i} className="b-slide">
              <img src={item} height="100" width="250" alt="" />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BrandsSlider;
