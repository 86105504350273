export const deepSearchByKey = (
  object,
  originalKey,
  originalValue,
  matches = []
) => {
  if (object != null) {
    if (Array.isArray(object)) {
      for (let arrayItem of object) {
        deepSearchByKey(arrayItem, originalKey, originalValue, matches);
      }
    } else if (typeof object == "object") {
      for (let key of Object.keys(object)) {
        if (key === originalKey) {
          if (object[key].toString() === originalValue.toString()) {
            matches.push(object);
          }
        } else {
          deepSearchByKey(object[key], originalKey, originalValue, matches);
        }
      }
    }
  }

  return matches;
};

export const goTo = (selector) => {
  setTimeout(() => {
    document.querySelector(selector).scrollIntoView({
      behavior: "smooth",
      top: 100,
    });
  }, 100);
};

export const extractPlaceData = (place) => {
  let state = undefined;
  let state_code = undefined;
  let city = undefined;
  let zip = undefined;

  if (!place || !place.address_components || !place.address_components.length)
    return {};

  place.address_components.forEach((cmp) => {
    if (cmp.types) {
      const types = cmp.types;

      if (types.includes("locality") && types.includes("political")) {
        city = cmp.long_name;
      } else if (
        types.includes("political") &&
        types.includes("administrative_area_level_1")
      ) {
        state = cmp.long_name;
        state_code = cmp.short_name;
      } else if (types.includes("postal_code")) {
        zip = cmp.long_name;
      }
    }
  });

  return { state, city, zip, state_code };
};
