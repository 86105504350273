export const setFormLoading = (form, status = true) => {
  let inputs = form.querySelectorAll("input");
  let submits = form.querySelectorAll("button[type=submit]");
  if (status == true) {
    form.classList.add("is-loading");
  } else {
    form.classList.remove("is-loading");
  }

  for (let i = 0; i < inputs.length; i++) {
    const input = inputs[i];
    input.disabled = status;
  }

  for (let i = 0; i < submits.length; i++) {
    const submit = submits[i];
    submit.disabled = status;
  }
};
