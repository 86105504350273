import { useEffect, useState } from "react";
import GlobalService from "../../services/Global.service";
import PageTitle from "../../components/PageTitle";

const FilterSidebar = (props) => {
  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);
  const [years, setYears] = useState([]);
  const [isCarLoading, setIsCarLoading] = useState([-1, -1, -1]);

  useEffect(() => {
    const loadCarMMY = async () => {
      let params = Object.fromEntries([...props.searchParams]);
      const makeRes = await GlobalService.makes();
      setMakes(makeRes.data.result.items);

      if (params.car_make) {
        const modelRes = await GlobalService.models(params.car_make);
        setModels(modelRes.data.result.items);
      } else {
        setModels([]);
      }

      if (params.car_model) {
        const yearRes = await GlobalService.years(params.car_model);
        setYears(yearRes.data.result.items);
      } else {
        setYears([]);
      }

      setIsCarLoading([0, 0, 0]);
    };

    setIsCarLoading([1, -1, -1]);
    loadCarMMY();
  }, [props.query.category]);

  const handleCarChange = async (e) => {
    let params = Object.fromEntries([...props.searchParams]);
    let newParams = { ...params };
    switch (e.target.name) {
      case "car_make":
        newParams.car_make = e.target.value ? e.target.value : "";
        newParams.car_model = "";
        newParams.car_year = "";
        setIsCarLoading([-1, 1, -1]);
        if (e.target.value) {
          const mres = await GlobalService.models(e.target.value);
          setModels(mres.data.result.items);
        } else {
          setModels([]);
        }
        setYears([]);
        break;

      case "car_model":
        newParams.car_model = e.target.value ? e.target.value : "";
        newParams.car_year = "";
        setIsCarLoading([-1, -1, 1]);
        if (e.target.value) {
          const yres = await GlobalService.years(e.target.value);
          setYears(yres.data.result.items);
        } else {
          setYears([]);
        }
        break;

      case "car_year":
        newParams.car_year = e.target.value;
        break;
    }

    newParams.page = 1;

    setIsCarLoading([0, 0, 0]);
    props.setSearchParams({
      ...newParams,
    });
  };

  const handleOpenClose = () => {
    props.setIsFiltersSide(!props.isFiltersSide);
  };

  return (
    <div
      className={`filters-box-container ${props.isFiltersSide ? "opened" : ""}`}
    >
      <div className="filters-box">
        <PageTitle text="Filter Result" className="mb-5" />
        <div className="filters-elements">
          <div className="search-box">
            <h3>By Part Name</h3>
            <label className="xlabel">Search</label>
            <input
              type="text"
              className="xinput"
              placeholder="Search by part name"
              value={props.query.text}
              name="text"
              onChange={props.handleChange}
            />
          </div>
          <div className="search-box">
            <h3>By Vehicle</h3>
            <div className="mt-3">
              <label className="xlabel">Car Make</label>
            </div>
            <select
              value={props.query.car_make ? props.query.car_make : ""}
              name="car_make"
              className={`xinput ${isCarLoading[0] == 1 ? "is-loading" : ""}`}
              disabled={isCarLoading[0] == -1}
              onChange={handleCarChange}
            >
              <option value="">All Makes</option>
              {makes
                ? makes.map((make) => {
                    return (
                      <option key={make.id} value={make.id}>
                        {make.name}
                      </option>
                    );
                  })
                : ""}
            </select>

            <div className="mt-3">
              <label className="xlabel">Car Model</label>
            </div>
            <select
              value={props.query.car_model ? props.query.car_model : ""}
              name="car_model"
              className={`xinput ${isCarLoading[1] == 1 ? "is-loading" : ""}`}
              disabled={isCarLoading[1] == -1}
              onChange={handleCarChange}
            >
              <option value="">All Models</option>
              {models && props.query.car_make
                ? models.map((model) => {
                    return (
                      <option key={model.id} value={model.id}>
                        {model.name}
                      </option>
                    );
                  })
                : ""}
            </select>

            <div className="mt-3">
              <label className="xlabel">Model Year</label>
            </div>
            <select
              value={props.query.car_year ? props.query.car_year : ""}
              name="car_year"
              className={`xinput ${isCarLoading[2] == 1 ? "is-loading" : ""}`}
              disabled={isCarLoading[2] == -1}
              onChange={handleCarChange}
            >
              <option value="">All Years</option>
              {years && props.query.car_model
                ? years.map((year) => {
                    return (
                      <option key={year.id} value={year.id}>
                        {year.year}
                      </option>
                    );
                  })
                : ""}
            </select>
          </div>

          <div className="search-box">
            <h3>By Brand</h3>
            <div className="mt-3">
              <label className="xlabel">Choose Brands</label>
            </div>
            <div className="options-box mt-1">
              {props.query.brand &&
                props.filters &&
                props.filters.brands.map((option) => {
                  return (
                    <label className="option-item" key={option.id}>
                      <div className="left">
                        <input
                          className="form-check-input me-2 mt-0"
                          type="checkbox"
                          value={option.id}
                          name="brand"
                          checked={props.query.brand.includes(
                            option.id.toString()
                          )}
                          onChange={props.handleChange}
                        />
                        <span className="total-num me-2">{option.name}</span>
                      </div>
                      {/* <div className="right">
                  <span className="map">(25)</span>
                </div> */}
                    </label>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="text-center">
          <button
            className="xbtn sm filter-btn only-mob"
            onClick={() => {
              props.setIsFiltersSide(false);
            }}
          >
            Show Results
          </button>
        </div>
      </div>
      <div className="filters-back-filter" onClick={handleOpenClose}></div>
    </div>
  );
};

export default FilterSidebar;
