import {
  imgAngleDown,
  imgBar,
  imgCart,
  imgHeadset,
  imgHeart,
  imgLogo,
  imgSearch,
  imgSort,
  imgUser,
} from "../../utils/Images";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/Auth.context";
import { useGlobal } from "../../contexts/Global.context";
import { useCart } from "../../contexts/Cart.context";
import { useState } from "react";
import { useWishlist } from "../../contexts/Wishlist.context";
import "./Navbar.scss";
import CategoriesDrop from "./CategoriesDrop";

function Navbar() {
  const { user, logout } = useAuth();
  const { wishlist } = useWishlist();
  const { cart } = useCart();
  const navigate = useNavigate();
  const [isLoggingOut, setIsLogginOut] = useState(false);
  const [searchText, setSearchText] = useState(null);
  const [isCategoryDrop, setIsCategoryDrop] = useState(false);
  const { setIsSideNav, info } = useGlobal();

  const handleLogout = async () => {
    setIsLogginOut(true);
    await logout();
    setIsLogginOut(false);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchText) {
      navigate(`search?text=${searchText}`);
      setSearchText(null);
    }
  };

  return (
    <>
      <header>
        <div className="main-navbar">
          <div className="top-nav">
            <div className="container d-flex align-items-center">
              <div className="left">
                <button
                  className="bar-btn"
                  onClick={() => {
                    setIsSideNav(true);
                  }}
                >
                  <img src={imgBar} />
                </button>
                <Link to="/" className="navbar-brand">
                  <img src={imgLogo} />
                </Link>
              </div>

              <div className="mid">
                <form className="search-form" onSubmit={handleSearch}>
                  <input
                    placeholder="Search for parts..."
                    value={searchText}
                    type="search"
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                  />
                  <button>
                    <img src={imgSearch} />
                  </button>
                </form>
              </div>

              <div className="right">
                {!user && (
                  <div className="n-item">
                    <img src={imgHeadset} />
                    <div className="content">
                      <span className="t">Contact Us</span>
                      <span className="b">{info?.phone1 ?? ""}</span>
                    </div>
                  </div>
                )}

                {user && (
                  <>
                    <Link to="/wishlist" className="n-item-2">
                      <span className="t">
                        {wishlist && wishlist.items
                          ? wishlist.items.length
                          : "0"}
                      </span>
                      <img src={imgHeart} />
                    </Link>

                    <Link to="/cart" className="n-item-2">
                      <span className="t">
                        {cart && cart.items ? cart.items.length : "0"}
                      </span>
                      <img src={imgCart} />
                    </Link>
                  </>
                )}

                {user ? (
                  <Link to="/profile/info" className="n-item-2 only-mob">
                    <img src={imgUser} />
                  </Link>
                ) : (
                  <Link to="/login" className="n-item-2 only-mob">
                    <img src={imgUser} />
                  </Link>
                )}

                <div className="n-item">
                  <img src={imgUser} />
                  <div className="content">
                    <span className="t">My Account</span>
                    {user ? (
                      <>
                        <span className="b">
                          {user.first_name} <img src={imgAngleDown} />
                          <ul
                            className={`dropdown-menu ${
                              isLoggingOut ? "is-loading" : ""
                            }`}
                            aria-labelledby="navbarDropdownMenuLink"
                          >
                            <li>
                              <Link
                                to="/profile/info"
                                className="dropdown-item"
                              >
                                My Account
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/profile/orders"
                                className="dropdown-item"
                              >
                                My Orders
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/profile/addresses"
                                className="dropdown-item"
                              >
                                My Addresses
                              </Link>
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                onClick={handleLogout}
                              >
                                Logout
                              </a>
                            </li>
                          </ul>
                        </span>
                      </>
                    ) : (
                      <span className="b">
                        <Link to="/login">Login</Link>&nbsp;-&nbsp;
                        <Link to="/register">Register</Link>
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Bot Nav */}
          <nav className="bot-nav">
            <div className="container">
              <div className="cats-drop">
                <button
                  className="cats-nav-btn"
                  onClick={() => {
                    setIsCategoryDrop(!isCategoryDrop);
                  }}
                >
                  <img src={imgSort} height={20} className="me-3" />
                  Shop By Category
                  <img src={imgAngleDown} height={20} className="ms-3" />
                </button>
                {isCategoryDrop && (
                  <CategoriesDrop setIsCategoryDrop={setIsCategoryDrop} />
                )}
              </div>

              <div className="links">
                <Link to={"/"}>Home</Link>
                <Link to={"/search?text="}>Products</Link>
                <Link to={"/about-us"}>About Us</Link>
                <Link to={"/contact-us"}>Contact Us</Link>
              </div>

              <div className="right"></div>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
}

export default Navbar;
