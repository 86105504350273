import config from "../config/config";
import apiWorker from "../utils/Api";

const root = `${config.root}/home`;

const home = async (body = {}) => {
  try {
    const res = await apiWorker().get(root, body);
    return res;
  } catch (error) {
    return false;
  }
};

export default { home };
