import config from "../config/config";
import apiWorker from "../utils/Api";

const root = `${config.root}/info`;

const all = async () => {
  try {
    const res = await apiWorker().get(root);
    return res;
  } catch (error) {
    if (error.response) {
      return error.response;
    }
    return false;
  }
};

const one = async (key) => {
  try {
    const res = await apiWorker().get(`${root}/${key}`);
    return res;
  } catch (error) {
    if (error.response) {
      return error.response;
    }
    return false;
  }
};

export default { all, one };
