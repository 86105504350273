import { imgBrands } from "../utils/Images";

function FeaturedBrands() {
  return (
    <>
      {/* <!--featured--> */}
      <div className="featured d-flex justify-content-between">
        <div className="freatured-brand">
          <h2>FEATURED BRANDS</h2>
          <h3>Every GM Brand to Fit Your Needs</h3>
          <span className="line"></span>
          <p>
            No matter what you are driving, we have got you covered. We carry
            only the best genuine OEM parts for Chevrolet, GMC, Buick, Cadilac,
            Pontiac, Saturn and Hummer brands. Order now and get the parts you
            need , at a great price!
          </p>
        </div>
        <div className="brand d-flex align-items-start flex-column">
          <img src={imgBrands} className="w-100" alt="" />
        </div>
      </div>
    </>
  );
}

export default FeaturedBrands;
