import { useEffect, useState } from "react";
import { useCart } from "../../contexts/Cart.context";
import AddressService from "../../services/Address.service";
import config from "../../config/config";
import OrderService from "../../services/Order.service";
import { useNavigate } from "react-router";
import AddEditAddressModal from "../../components/modals/AddEditAddress.modal";
import { useGlobal } from "../../contexts/Global.context";

const CartSummary = (props) => {
  const { cart, refreshCart } = useCart();
  const [addresses, setAddresses] = useState(null);
  const [data, setData] = useState({
    description: "new order",
    branch_id: config.defaultBranchId,
    address_id: "",
    shipping_method: "PICKUP",
  });
  const { showModal } = useGlobal();
  const navigate = useNavigate();

  const loadAddresses = async () => {
    const res = await AddressService.list();
    if (res && res.data && res.data.result) {
      setAddresses(res.data.result.addresses);
      setData((old) => {
        return {
          ...old,
          delivery_fee: res.data.result.delivery_fee,
        };
      });
    }
  };

  useEffect(() => {
    loadAddresses();
  }, []);

  const handleChange = (e) => {
    setData((old) => {
      return {
        ...old,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleCheckout = async () => {
    if (data.shipping_method == "DELIVERY" && !data.address_id) {
      return;
    }

    props.setIsOrdering(true);

    const res = await OrderService.post(data);
    if (res && res.data && res.data.success) {
      refreshCart();
      navigate(`/order/${res.data.result.saved_order_id}/pay`);
    }

    props.setIsOrdering(false);
  };

  return cart ? (
    <>
      <div>
        <table className="cart-summary-table ms-auto">
          <thead>
            <tr>
              <td>
                <h2 className="card-summary my-3 mt-5">Cart Summary</h2>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p className="order-title color-card-title mb-1">Subtotal:</p>
              </td>
              <td>
                <p className="total-num color-card-num mb-1">
                  ${cart.sub_total_price}
                </p>
              </td>
            </tr>

            {data.shipping_method == "DELIVERY" && (
              <tr>
                <td>
                  <p className="order-title color-card-title mb-1">Shipping:</p>
                </td>
                <td>
                  <p className="total-num color-card-num mb-1">
                    ${data.delivery_fee ?? 0}
                  </p>
                </td>
              </tr>
            )}

            <tr>
              <td>
                <p className="order-title color-card-title mb-1">Tax ({cart.branchTax}%):</p>
              </td>
              <td>
                <p className="total-num color-card-num mb-1">${cart.tax}</p>
              </td>
            </tr>

            <tr>
              <td>
                <hr />
              </td>
              <td>
                <hr />
              </td>
            </tr>

            <tr>
              <td>
                <p className="order-title color-card-title mb-1">Total:</p>
              </td>
              <td>
                <p className="total-num color-card-num mb-1">
                  <b>${cart.total_price}</b>
                </p>
              </td>
            </tr>

            <tr>
              <td>
                <hr />
              </td>
              <td>
                <hr />
              </td>
            </tr>

            <tr>
              <td>
                <p className="order-title color-card-title mb-1">
                  Shipping Method:
                </p>
              </td>
              <td>
                <select
                  value={data.shipping_method}
                  className="d-block bg-none border w-100 p-2 mb-2"
                  onChange={handleChange}
                  name="shipping_method"
                >
                  <option value="PICKUP">Pick-up</option>
                  <option value="DELIVERY">Delivery</option>
                </select>
              </td>
            </tr>
            {data.shipping_method == "DELIVERY" && (
              <tr>
                <td>
                  <p className="order-title color-card-title mb-1">Address:</p>
                </td>
                <td>
                  <select
                    disabled={!addresses}
                    value={data.address_id}
                    className="d-block bg-none border w-100 p-2 mb-2"
                    onChange={handleChange}
                    name="address_id"
                  >
                    <option>Select Address</option>
                    {addresses &&
                      addresses.map((a, i) => {
                        return (
                          <option value={a.id} key={i}>
                            {a.name}
                          </option>
                        );
                      })}
                  </select>
                  <div className="text-end">
                    <button
                      className="text-decoration-underline fs-sm bg-transparent border-0"
                      onClick={() => {
                        showModal("address");
                      }}
                    >
                      + Add new address
                    </button>
                  </div>
                </td>
              </tr>
            )}

            <tr>
              <td></td>
              <td>
                <button
                  type="submit"
                  className="d-inline-flex w-auto justify-content-between align-items-center xbtn mt-3"
                  disabled={!addresses}
                  onClick={handleCheckout}
                >
                  Checkout
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    className="ms-4"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M1 8.00063C1 7.86802 1.05268 7.74085 1.14645 7.64708C1.24021 7.55331 1.36739 7.50063 1.5 7.50063H13.293L10.146 4.35463C10.0521 4.26075 9.99937 4.13341 9.99937 4.00063C9.99937 3.86786 10.0521 3.74052 10.146 3.64663C10.2399 3.55274 10.3672 3.5 10.5 3.5C10.6328 3.5 10.7601 3.55274 10.854 3.64663L14.854 7.64663C14.9006 7.69308 14.9375 7.74825 14.9627 7.809C14.9879 7.86974 15.0009 7.93486 15.0009 8.00063C15.0009 8.0664 14.9879 8.13152 14.9627 8.19226C14.9375 8.25301 14.9006 8.30819 14.854 8.35463L10.854 12.3546C10.7601 12.4485 10.6328 12.5013 10.5 12.5013C10.3672 12.5013 10.2399 12.4485 10.146 12.3546C10.0521 12.2607 9.99937 12.1334 9.99937 12.0006C9.99937 11.8679 10.0521 11.7405 10.146 11.6466L13.293 8.50063H1.5C1.36739 8.50063 1.24021 8.44795 1.14645 8.35419C1.05268 8.26042 1 8.13324 1 8.00063V8.00063Z"
                      fill="white"
                    />
                  </svg>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <AddEditAddressModal
        onSuccess={() => {
          loadAddresses();
        }}
      />
    </>
  ) : (
    ""
  );
};

export default CartSummary;
