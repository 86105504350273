import config from "../config/config";
import apiWorker from "../utils/Api";

const root = `${config.root}/promotions`;

const list = async () => {
  try {
    const res = await apiWorker().get(root);
    return res;
  } catch (error) {
    return false;
  }
};

export default { list };
