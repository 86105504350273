import "./HeroSlider.scss";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import { useGlobal } from "../../contexts/Global.context";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const HeroSlider = () => {
  const { homeSliders } = useGlobal();

  //   const getSlides = ()=>{
  //       return home
  //   }

  useEffect(() => {
    setTimeout(() => {
      document.getElementsByClassName("swiper-pagination-bullet")[0].click();
    }, 1000);
  }, []);

  return (
    <div className="hero-slider">
      <Swiper
        modules={[Autoplay, Pagination]}
        spaceBetween={30}
        effect="fade"
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
          pauseOnMouseEnter: false,
          waitForTransition: false,
        }}
        mousewheel={{
          invert: true,
        }}
        pagination={{
          el: ".blog-slider__pagination",
          clickable: true,
        }}
        className="blog-slider"
      >
        <div className="blog-slider__wrp swiper-wrapper">
          {homeSliders.map((s) => {
            return (
              <SwiperSlide className="blog-slider__item swiper-slide">
                <div className="blog-slider__img">
                  <img src={s.img} alt="" />
                </div>
                <div className="blog-slider__content">
                  <span className="blog-slider__code">4×4 Motors</span>
                  <div className="blog-slider__title">{s.title}</div>
                  <div className="blog-slider__text">{s.description}</div>
                  <Link to={`/product/${s.product_id}`} className="xbtn">
                    {s.button_text}
                  </Link>
                </div>
              </SwiperSlide>
            );
          })}
        </div>
        <div className="blog-slider__pagination"></div>
      </Swiper>
    </div>
  );
};

export default HeroSlider;
