const OrderSummary = (props) => {
  return (
    <div className="">
      <table className="cart-summary-table">
        <thead>
          <tr>
            <td>
              <h2 className="card-summary my-3 mt-5">Order Summary</h2>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <p className="order-title color-card-title mb-1">Subtotal:</p>
            </td>
            <td>
              <p className="total-num color-card-num mb-1">
                ${props.item ? props.item.sub_total : "-"}
              </p>
            </td>
          </tr>

          <tr>
            <td>
              <p className="order-title color-card-title mb-1">Shipping:</p>
            </td>
            <td>
              <p className="total-num color-card-num mb-1">
                ${props.item ? props.item.delivery_fee : "-"}
              </p>
            </td>
          </tr>

          <tr>
            <td>
              <p className="order-title color-card-title mb-1">Tax:</p>
            </td>
            <td>
              <p className="total-num color-card-num mb-1">
                ${props.item ? props.item.tax : "-"}
              </p>
            </td>
          </tr>

          <tr>
            <td>
              <hr />
            </td>
            <td>
              <hr />
            </td>
          </tr>

          <tr>
            <td>
              <p className="order-title color-card-title mb-1">Total:</p>
            </td>
            <td>
              <p className="total-num color-card-num mb-1">
                <b>${props.item ? props.item.total : "-"}</b>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <hr />
      {props.item && props.item.items && (
        <>
          <h2 className="card-summary my-3 mt-2">Order Items</h2>
          {props.item.items.map((o_item) => {
            return (
              <div key={o_item.id} className="product-item-sm">
                <img src={o_item.product?.image?.path} />
                <div>
                  <span className="ps-n">{o_item.quantity} x {o_item.product?.name}</span>
                  <span className="ps-p">${o_item.price}</span>
                </div>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default OrderSummary;
