const PageTitle = (props) => {
  return (
    <h2 className={`xtitle ${props.className ? props.className : ''}`}>
      <span>
        <b>{props.text}</b>
      </span>
    </h2>
  );
};

export default PageTitle;
