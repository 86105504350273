import config from "../config/config";
import apiWorker from "../utils/Api";

const root = `${config.root}/carts`;

const list = async () => {
  try {
    const res = await apiWorker().get(
      `${config.root}/branch-carts?branch_id=${config.defaultBranchId}`
    );
    return res;
  } catch (error) {
    return false;
  }
};

const post = async (body) => {
  try {
    const res = await apiWorker().post(root, body);
    return res;
  } catch (error) {
    return false;
  }
};

const put = async (id, body) => {
  try {
    const res = await apiWorker().put(`${root}/${id}`, body);
    return res;
  } catch (error) {
    if (error && error.response) {
      return error.response;
    }
    return false;
  }
};

const remove = async (id) => {
  try {
    const res = await apiWorker().delete(`${root}/${id}`);
    return res;
  } catch (error) {
    return false;
  }
};

export default { post, put, list, remove };
