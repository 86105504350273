import "./HeroSection.scss";
import HeroSlider from "../HeroSlider/HeroSlider";
import SideFindForm from "../SideFindForm/SideFindForm";

const HeroSection = () => {
  return (
    <>
      <div className="hero-slider-section">
        <div className="container">
          <div className="row hero-slider-section-row">
            <div className="col-lg-8">
              <HeroSlider />
            </div>
            <div className="col-lg-4">
              <SideFindForm />
            </div>
          </div>
        </div>
      </div>
      <div className="hero-slider-section-over"></div>
    </>
  );
};

export default HeroSection;
