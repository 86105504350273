import { useEffect, useState } from "react";
import { useCart } from "../../contexts/Cart.context";
import { Link } from "react-router-dom";

const CartItem = (props) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isPutting, setIsPutting] = useState(false);
  const [timer, setTimer] = useState(null);
  const [lastQty, setLastQty] = useState(0);
  const [item, setItem] = useState(null);
  const [available, setAvailable] = useState(null);

  const { removeFromCart, updateInCart } = useCart();

  const handleAddQty = (e) => {
    let qty = item.quantity + 1;
    setItem({
      ...item,
      quantity: qty,
    });
    updateItem(qty);
  };

  const handleMinusQty = (e) => {
    let qty = item.quantity - 1;
    if (qty <= 0) qty = 1;
    setItem({
      ...item,
      quantity: qty,
    });
    updateItem(qty);
  };

  useEffect(() => {
    if (isDeleting) {
      removeFromCart(item.id);
    }
  }, [isDeleting]);

  useEffect(() => {
    setItem(props.item);
    setLastQty(props.item.quantity);
  }, []);

  useEffect(() => {
    if (item) {
      setAvailable(item, item.variation);
    }
  }, [item]);

  const updateItem = (qty) => {
    if (isPutting || !item) return;

    const edit = async () => {
      props.setIsEditinCart(true);
      setIsPutting(true);
      const res = await updateInCart(item.id, {
        quantity: qty,
      });

      if (res && res.data && res.data.success) {
        setLastQty(qty);
      } else {
        setItem({
          ...item,
          quantity: lastQty,
        });
      }

      props.setIsEditinCart(false);
      setIsPutting(false);
    };

    clearTimeout(timer);
    setTimer(
      setTimeout(() => {
        edit();
      }, 400)
    );
  };

  useEffect(() => {}, [isPutting]);

  return (
    <>
      {item ? (
        <tr>
          <td>
            <Link
              to={`/product/${item.product.id}`}
              className="d-flex align-items-center my-2"
            >
              <img
                src={item.product.image?.path}
                width="60"
                height="60"
                style={{ flexShrink: 0, objectFit: "contain" }}
                className="me-2"
              />

              <div className="text-start">
                <b>{item.variation.sku ? item.variation.sku : ""}</b>
                <p className="mb-0 pe-1">{item.product.name}</p>
              </div>
            </Link>
          </td>
          {/* <td>{item.variation.sku ? item.variation.sku : ""}</td>
        <td></td> */}
          <td className="qty-td">
            {available ? (
              <div
                className={`m-auto d-flex justify-content-between align-items-center number ${
                  isPutting ? "is-loading" : ""
                }`}
              >
                <button
                  disabled={isDeleting}
                  className="minus"
                  onClick={handleMinusQty}
                >
                  <svg
                    width="9"
                    height="4"
                    viewBox="0 0 9 4"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.50192 0.947266H0V3.07391H8.50192V0.947266Z"
                      fill="white"
                    />
                  </svg>
                </button>
                <span>{item.quantity}</span>
                <button
                  disabled={isDeleting}
                  className="plus"
                  onClick={handleAddQty}
                >
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.14447 3.94684H0.642578V6.07349H9.14447V3.94684Z"
                      fill="white"
                    />
                    <path
                      d="M5.95605 9.26349L5.95605 0.756836L3.83057 0.756836L3.83057 9.26349H5.95605Z"
                      fill="white"
                    />
                  </svg>
                </button>
              </div>
            ) : (
              "OUT OF STOCK"
            )}
          </td>
          <td>
            ${item.variation.price}
            {item.variation.old_price != item.variation.price && (
              <span className="px-2 text-decoration-line-through d-block text-secondary">
                ${item.variation.old_price}
              </span>
            )}
          </td>
          <td className="fw-bold">${item.variation.price * item.quantity}</td>
          <td>
            <button
              onClick={() => {
                setIsDeleting(true);
              }}
              className={`heart p-1 mx-3 ${isDeleting ? "is-loading" : ""}`}
            >
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 10.8008H13.2V20.4008H12V10.8008ZM14.4 10.8008H15.6V20.4008H14.4V10.8008ZM16.8 10.8008H18V20.4008H16.8V10.8008ZM7.19995 7.20078H22.8V8.40078H7.19995V7.20078ZM18 7.20078H16.8V6.60078C16.8 6.24078 16.56 6.00078 16.2 6.00078H13.8C13.44 6.00078 13.2 6.24078 13.2 6.60078V7.20078H12V6.60078C12 5.58078 12.78 4.80078 13.8 4.80078H16.2C17.22 4.80078 18 5.58078 18 6.60078V7.20078Z"
                  fill="#AFAFAF"
                />
                <path
                  d="M18.5999 24.0002H11.3999C10.4399 24.0002 9.5999 23.2202 9.4799 22.2602L8.3999 7.86023L9.5999 7.74023L10.6799 22.1402C10.6799 22.5002 11.0399 22.8002 11.3999 22.8002H18.5999C18.9599 22.8002 19.2599 22.5002 19.3199 22.1402L20.3999 7.74023L21.5999 7.86023L20.5199 22.2602C20.3999 23.2202 19.5599 24.0002 18.5999 24.0002Z"
                  fill="#AFAFAF"
                />
              </svg>
            </button>
          </td>
        </tr>
      ) : (
        ""
      )}
    </>
  );
};

export default CartItem;
