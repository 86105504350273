import config from "../config/config";
import apiWorker from "../utils/Api";

const root = `${config.root.replace(
  "/web",
  ""
)}/payment-way/payment-providers?is_enabled=1`;

const stripe = async () => {
  try {
    const res = await apiWorker({}).get(root);
    if (res.data && res.data.success) {
      const p = res.data.result.payment_providers.filter(
        (p) => p.name == "stripe"
      );
      if (p.length > 0) {
        return p[0];
      }
    }
    return false;
  } catch (error) {
    return false;
  }
};

export default {
  stripe,
};
