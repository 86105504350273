export default class FormHandler {
  state = null;
  setState = null;

  constructor(state, setState) {
    this.state = state;
    this.setState = setState;
  }

  handleTextChange = (e) => {
    let val = {};
    if (e.target.type == "checkbox") {
      val = {
        [e.target.name]: e.target.checked,
      };
    } else {
      val = {
        [e.target.name]: e.target.value,
      };
    }

    this.setState({ ...this.state, ...val });
  };
}
