export const getAvailable = (item, variation) => {
  if (variation && variation.in_stock < 1) return false;

  if (item.tracking_type == 1) {
    return {
      max: 9999,
    };
  } else {
    return {
      max: variation.available_quantity,
    };
  }
};
