import { useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle";
import FormHandler from "../../utils/Handlers";
import { useAuth } from "../../contexts/Auth.context";
import { setFormLoading } from "../../utils/UiHelpers";
import ContactService from "../../services/Contact.service";
import { Link } from "react-router-dom";

function ContactPage() {
  const { user, isAuth } = useAuth();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [form, setForm] = useState(user ?? {});
  const formHandler = new FormHandler(form, setForm);

  useEffect(() => {
    if (isAuth() && user) {
      setForm({
        name: `${user?.first_name} ${user?.last_name}`,
        email: user.email,
        phone: user.phone_number,
      });
    }
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setFormLoading(e.target);
    let res = await ContactService.post(form);
    if (res) {
      if (res.data && res.data.success == true) {
        setSuccess("yes");
      } else {
        setError(res.data.message);
      }
    } else {
      setError("error");
    }
    setFormLoading(e.target, false);
  };

  return success != "yes" ? (
    <form onSubmit={handleSubmit}>
      <div className="container">
        <br />
        <br />

        <PageTitle text="Contact Us" />

        <div className="section-title-2 mb-45">
          <br />
          <p
            className="text-center"
            style={{
              maxWidth: 500,
              margin: "auto",
            }}
          >
            You can call us in working time or visit our office. All mails will
            get the response within 24 hours. Love to hear from you!
          </p>
          <br />
          <br />
        </div>

        <div className="contact-info-wrap-2 mb-40">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-12 col-sm-5">
              <div className="single-contact-info3-wrap mb-30">
                <div className="single-contact-info3-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    id="Outline"
                    viewBox="0 0 24 24"
                    width="35"
                    height="35"
                    fill="#fd3838"
                  >
                    <path d="M12,6a4,4,0,1,0,4,4A4,4,0,0,0,12,6Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,12Z" />
                    <path d="M12,24a5.271,5.271,0,0,1-4.311-2.2c-3.811-5.257-5.744-9.209-5.744-11.747a10.055,10.055,0,0,1,20.11,0c0,2.538-1.933,6.49-5.744,11.747A5.271,5.271,0,0,1,12,24ZM12,2.181a7.883,7.883,0,0,0-7.874,7.874c0,2.01,1.893,5.727,5.329,10.466a3.145,3.145,0,0,0,5.09,0c3.436-4.739,5.329-8.456,5.329-10.466A7.883,7.883,0,0,0,12,2.181Z" />
                  </svg>
                </div>
                <div className="single-contact-info3-content">
                  <h3>Address</h3>
                  <p className="width-1">Michigan USA</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 col-sm-7">
              <div className="single-contact-info3-wrap mb-30">
                <div className="single-contact-info3-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    id="Layer_1"
                    data-name="Layer 1"
                    viewBox="0 0 24 24"
                    width="35"
                    height="35"
                    fill="#fd3838"
                  >
                    <path d="M13,1a1,1,0,0,1,1-1A10.011,10.011,0,0,1,24,10a1,1,0,0,1-2,0,8.009,8.009,0,0,0-8-8A1,1,0,0,1,13,1Zm1,5a4,4,0,0,1,4,4,1,1,0,0,0,2,0,6.006,6.006,0,0,0-6-6,1,1,0,0,0,0,2Zm9.093,10.739a3.1,3.1,0,0,1,0,4.378l-.91,1.049c-8.19,7.841-28.12-12.084-20.4-20.3l1.15-1A3.081,3.081,0,0,1,7.26.906c.031.031,1.884,2.438,1.884,2.438a3.1,3.1,0,0,1-.007,4.282L7.979,9.082a12.781,12.781,0,0,0,6.931,6.945l1.465-1.165a3.1,3.1,0,0,1,4.281-.006S23.062,16.708,23.093,16.739Zm-1.376,1.454s-2.393-1.841-2.424-1.872a1.1,1.1,0,0,0-1.549,0c-.027.028-2.044,1.635-2.044,1.635a1,1,0,0,1-.979.152A15.009,15.009,0,0,1,5.9,9.3a1,1,0,0,1,.145-1S7.652,6.282,7.679,6.256a1.1,1.1,0,0,0,0-1.549c-.031-.03-1.872-2.425-1.872-2.425a1.1,1.1,0,0,0-1.51.039l-1.15,1C-2.495,10.105,14.776,26.418,20.721,20.8l.911-1.05A1.121,1.121,0,0,0,21.717,18.193Z" />
                  </svg>
                </div>
                <div className="single-contact-info3-content">
                  <h3>Contact</h3>
                  <p>
                    Mobile: <span>(+1) - 3135865937</span>
                  </p>
                  <p>
                    Mail: <span>4by4motors@gmail.com</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 col-sm-12">
              <div className="single-contact-info3-wrap mb-30">
                <div className="single-contact-info3-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    id="Outline"
                    viewBox="0 0 24 24"
                    width="35"
                    height="35"
                    fill="#fd3838"
                  >
                    <path d="M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0Zm0,22A10,10,0,1,1,22,12,10.011,10.011,0,0,1,12,22Z" />
                    <path d="M12,6a1,1,0,0,0-1,1v4.325L7.629,13.437a1,1,0,0,0,1.062,1.7l3.84-2.4A1,1,0,0,0,13,11.879V7A1,1,0,0,0,12,6Z" />
                  </svg>
                </div>
                <div className="single-contact-info3-content">
                  <h3>Hour of operation</h3>
                  <p> Monday - Friday: 09:00 - 20:00 </p>
                  <p> Sunday &amp; Saturday: 10:30 - 22:00</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        {form && (
          <>
            <div
              style={{
                width: "100%",
                maxWidth: "500px",
                margin: "auto",
              }}
            >
              <div className="row">
                <div className="col-md-12">
                  <label className="xlabel">Full Name</label>
                  <input
                    autoFocus
                    required
                    name="name"
                    type="text"
                    placeholder="Enter your full name"
                    className="xinput"
                    value={form.name}
                    onChange={formHandler.handleTextChange}
                  />
                </div>

                <div className="col-md-12">
                  <label className="xlabel">Email Address</label>
                  <input
                    type="email"
                    name="email"
                    className="xinput"
                    placeholder="Enter your email address"
                    value={form.email}
                    onChange={formHandler.handleTextChange}
                  />
                </div>

                <div className="col-md-12">
                  <label className="xlabel">Phone Number</label>
                  <input
                    type="number"
                    name="phone"
                    className="xinput"
                    value={form.phone}
                    onChange={formHandler.handleTextChange}
                    placeholder="Enter your phone number"
                  />
                </div>

                <div className="col-md-12">
                  <label className="xlabel">Message</label>
                  <textarea
                    className="xinput"
                    name="message"
                    value={form.message}
                    onChange={formHandler.handleTextChange}
                    placeholder="Enter your message"
                    rows={5}
                  ></textarea>
                </div>

                <div className="mb-3 d-flex">
                  <label className="form-check ">
                    <input
                      required
                      name="is_agreed"
                      className="form-check-input"
                      type="checkbox"
                      checked={form.is_agreed}
                      onChange={formHandler.handleTextChange}
                    />
                    <span className="agree">
                      By clicking this box, you agree to receive SMS from 4x4
                      Motors, you can reply stop to opt-out at any time,{" "}
                      <Link to="/privacy-policy">
                        &nbsp;Privacy Policy&nbsp;
                      </Link>
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <div className="text-center">
              <span className="text-danger text-center d-block fw-bold mb-3">
                {error}
              </span>
              <button className="xbtn" type="submit">
                Send Message
              </button>
            </div>
          </>
        )}
        <br />
      </div>
    </form>
  ) : (
    <div className="text-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="100"
        height="100"
        style={{
          display: "block",
          margin: "60px auto",
          marginBottom: 0,
        }}
        x="0"
        y="0"
        viewBox="0 0 512 512"
      >
        <g>
          <path
            d="M444.176 0 183.305 242.012 72.98 124.277 0 192.664l178.234 190.207L512 73.52zm0 0"
            fill="#28cd33"
          ></path>
        </g>
      </svg>
      <h3
        className="font-medium"
        style={{
          marginBottom: "40px",
          fontWeight: "600",
          marginBottom: "30px",
        }}
      >
        Message Sent
      </h3>
      <p
        style={{
          marginBottom: "10px",
          maxWidth: 400,
          fontSize: 18,
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        Your message has been sent. We'll get back to you as soon as possible
      </p>

      <Link
        to="/"
        className="xbtn"
        style={{
          marginTop: 10,
          marginBottom: 60,
        }}
      >
        Go Home
      </Link>
    </div>
  );
}

export default ContactPage;
