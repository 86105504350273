import { useEffect, useState } from "react";
import InfoService from "../../services/Info.service";
import { imgLoading3 } from "../../utils/Images";
import parse from "html-react-parser";
import PageTitle from "../../components/PageTitle";

const AboutPage = () => {
  const [data, setData] = useState(null);

  const load = async () => {
    const res = await InfoService.one("about");
    if (res && res.data) {
      setData(res.data.result);
    }
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <div className="container">
      <PageTitle className="my-5" text="About Us" />
      <div style={{ minHeight: "30vh" }}>
        {data ? (
          <>{parse(data)}</>
        ) : (
          <img src={imgLoading3} className="d-block m-auto" height="80" />
        )}
      </div>
    </div>
  );
};

export default AboutPage;
